/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import {
  ChefOrderStatuses,
  MealTimes, OrderTypes, PaymentStatusEnum, PurchaseOrderStatuses, ProductTypesEnum, OrderCategoryEnum,
} from '../../enums/order.enum';
import { IKitchenDetails, IUserAddress } from '../../constants/place-order.constants';
import { environment } from '../../environments/environment';
import { envName, RestApiService } from '../api.service';
import { HttpClientService } from '../http-client.service';
import { LogisticOrderStatus } from './logistic-orders.service';

interface IGetDashboardOrderMetricsInput {
  fromDate: string,
  toDate: string,
  parentZoneId?: string;
}

export interface IRating {
  createdAt: string;
  orderId: string;
  rating: number;
  ratingId: string;
  review?: string;
  revieweeId: string;
  // revieweeType: 'kitchen' | 'rider' | 'item' | '3pl-rider';
  revieweeType: string;
  reviewerId: string;
  // reviewerType: 'customer' | 'rider';
  reviewerType: string;
  updatedAt: string;
}

export interface IListOfOrdersToCheck {
  orderId: string;
  userId: string;
  isFirstTimeOrder?: boolean;
}

export interface IFirstTimeOrderPayload {
  orderId: string;
  userId: string;
}

export interface ILocationCoordinates {
  coordinates: number[];
  type: string;
}

export interface IOrderLog {
  createdAt: string;
  orderId: string;
  orderStatus: string;
  updatedAt: string;
  updatedBy: string;
  orderStatusName?: string,
  __v: number;
  _id: string;
}

export interface IDistanceStats {
  firstMileDelivery: number,
  lastMileDelivery: number,
  riderOrderAcceptanceLocation: ILocationCoordinates;
}

export interface IOrderResponse {
  orderStatusLabel?: string;
  _id: string,
  itemDetails: {
    itemId: string;
    itemCategory: string;
    itemName: string;
    quantity: number;
    price: number;
  }[],
  customerLocation: ILocationCoordinates,
  address: {
    line1Address: string;
    state: string;
    pincode: string;
    houseNo: string;
    street: string;
    landmark: string;
    locality: string;
    postalCode: string;
    addressType: string;
    location: ILocationCoordinates,
  },
  productCount?: number,
  addressId?: string,
  customerName: string;
  customerMobile: string;
  cookName: string;
  cookMobile: string;
  mealTime: string;
  city: string;
  instructions: string;
  sgst?: number;
  cgst?: number;
  igst?: number;
  interState: boolean;
  orderStatus: string,
  razorPayObject: {
    id: string;
    entity: string;
    amount: number;
    amount_paid: number;
    amount_due: number;
    currency: string;
    receipt: string;
    offer_id: string | null;
    status: string;
    attempts: number;
    notes: [],
    created_at: number;
  },
  deliveryTimeSlot: {
    date: string;
    to: string;
    time: string;
  },
  orderedDateTime: string,
  paymentType: string;
  parentZoneId?: string;
  subZoneId?: string;
  paymentStatus: string;
  deliveryCharge: number;
  totalPayment: number;
  orderType: string;
  cookId: string;
  kitchenId: string;
  userId: string;
  orderId: string;
  razorPayOrderId: string;
  paymentFailedReason?: string;
  notes?: string;
  kitchenAddress: IUserAddress,
  kitchenName: string;
  riderId?: string;
  riderName?: string;
  riderMobileNumber?: string;
  riderTipAmount?: number;
  customerRatings?: IRating[];
  riderRatings?: IRating[];
  cancelOrderDescription?: string;
  revisedSgst?: 0,
  revisedCgst?: 0,
  revisedIgst?: 0,
  discountedItemTotal?: 0,
  discountedNetAmount?: 0,
  class?: string;
  ratingStarAssetLink?: string;
  isProductInclude?: boolean;
  isPreorder?: boolean;
  couponId?: string;
  razorPayPaymentId?: string;
  isFirstTimeOrder?: boolean;
  orderedByAdmin?: string;
  specialInstructionAcknowledged?: boolean;
  logisticsOrderId?: string,
  couponDetails?: {
    _id: string;
    termsAndCondition: string;
    detailedDescription: string;
    couponId: string;
    couponCode: string;
    discountType: string;
    discount: number;
    savingsOnDiscount: number;
    couponName: string,
    description: string;
    isActive: boolean,
    constraints: [
      {
        maxRedemption: number;
      }
    ],
  };
  createdAt: string;
  updatedAt: string;
  specialCuisineType?: string;
  labelIcon?: string;
  __v: number;

}

export interface IItemDetails {
  itemId: string;
  itemCategory: string;
  itemName: string;
  quantity: number;
  price: number;
}

export interface ICouponDetails {
  _id: string;
  termsAndCondition: string;
  detailedDescription: string;
  couponId: string;
  couponCode: string;
  discountType: string;
  discount: number;
  savingsOnDiscount: number;
  couponName: string,
  description: string;
  isActive: boolean,
  constraints: [
    {
      maxRedemption: number;
    }
  ],
}

export interface IOrderDetails extends IOrderResponse {
  billedAmount: number;
  cgst: number;
  createdAt: string;
  customerRatings: IRating[];
  deliveryCharge: number;
  differentlyAbledType: string;
  foodCuisineStyle: string[];
  itemDetails: Array<IItemDetails>,
  kitchenLocation?: ILocationCoordinates;
  netAmount: number;
  orderLogs: IOrderLog[];
  riderLocation?: ILocationCoordinates;
  roundOff: number;
  sgst: number;
  revisedSgst?: 0,
  revisedCgst?: 0,
  discountedItemTotal?: 0,
  discountedNetAmount?: 0,
  razorPayPaymentId?: string;
  specialInstructionAcknowledged?: boolean;
  riderTipAmount?: number;
  couponDetails?: ICouponDetails,
  distanceStats: Partial<IDistanceStats>,
  igst?: 0,
  revisedIgst?: 0,
  orderedByAdmin: string;
  isFirstTimeCustomer?: boolean;
  logisticsOrderId: string;
  tplVendorName: string;
  tplTrackingUrl?: string;
  chefOrderId?: string;
  satelliteStoreId?: string;
}

export interface IGetDashboardOrderMetricsResponse {
  itemsCount: number,
  ridersCount: number,
  customersCount: number,
  cookCount: number,
  totalSales: number,
  ordersCount: number,
  avgOrderPrice: number,
  ordersByCook: [],
  itemsQuantityByOrder: [],
  activeCookCount: number;
  activeRiderCount: number;
  activeUserCount: number;
  cancelledOrders: number;
  delayedOrders: number,
  failedOrders: number,
  firstTimeOrders: number,
  preOrders: number,
  refundedOrders: number,
}

export interface IRefundDetail {
  refundAmount: number,
  refundStatus: string,
  refundId: string,
  totalPayment: number,
  refundReason?: string,
  totalRefundedAmount: number,
  initiatedBy?: string;
  createdAt: Date;
}
export interface IRefundResponse {
  bank?: string;
  capturedStatus?: string;
  method?: string;
  paymentId?: string;
  bankTransactionId?: string;
  upiTransactionId?: string;
  refunds?: IRefundDetail[];
}
export interface IKitchenOrderDetail {
  kitchenId: string;
  kitchenName: string;
  orderCount: number;
  parentZoneId: string;
  subZoneId: string;
  orders: {
    orderId: string;
    customerName: string;
    deliveryTimeSlot: {
      time: string;
    };
    orderStatus: string;
    class?: string;
  }[];
}

export enum RefundOptionTypes {
  OPTIMUM = 'optimum',
  NORMAL = 'normal',
}

export interface INewDashboardMetricsResponse {
  lifetimeMetrics: {
    totalOrders: number,
    totalSales: number,
    totalCustomers: number,
    totalCooks: number,
    totalRiders: number,
    totalItems: number,
  },
  orders: {
    totalSoldOrders: number,
    totalSoldOrderValue: number,
    delayedOrders: number,
    totalReceivedOrders: number,
    totalReceivedOrderValue: number,
    totalDeliveryChargeApplied: number,
    couponAddedOrders: number,
    totalCouponValue: number,
    avgOrdersPerDay: number,
    avgOrdersPrice: number,
    delayedOrderValue: number,
    cancelledOrders: number,
    undeliveredOrders: number,
    undeliveredOrdersValue: number,
    failedOrders: number,
    refundedOrders: number,
    refundedOrderValue: number,
    onlineOrdersValue: number,
    cashOrdersValue: number,
    onlineOrders: number,
    cashOrders: number,
    avgOrdersPriceExDeliveryCharges?: number,
    avgOrdersPriceIncDeliveryCharges?: number,
    orderRevenue?: number,
    averageDeliveryCharge?: number,
    totalGstAmount: number,
    avgGstAmount: number,
    avgCouponValue: number,
    totalActualSalesValue: number,
    avgActualSalesValue: number,
    avgCouponValueForTotalSalesOrder: number,
  },
  registeredUsers: {
    registeredCustomers: number,
    registeredRiders: number,
    registeredCooks: number;
  },
  users: {
    firstTimeCustomers: number,
    singleTimeOrderedCustomers: number,
    uniqueCustomers: number,
    uniqueCooks: number,
    uniqueRiders: number;
  },
  itemsAndProducts: {
    itemsAdded: number,
    productsAdded: number,
    distinctItemsOrdered: number,
    itemsOrderedValue: number,
    distinctProductsOrdered: number,
    productsOrderedValue: number,
  },
  preOrders: {
    preOrdersToBeDelivered: number,
    valueOfPreOrdersToBeDelivered: number,
    preOrdersPlaced: number,
    valueOfPreOrdersPlaced: number,
  };
  mealTimes: {
    breakfast: number,
    lunch: number,
    snacks: number,
    dinner: number,
  };
}

export interface IPurchaseOrderDetailsCustomerAddressLocation {
  type: string;
  coordinates: Array<number>;
}

export interface IReceiverDetails {
  name: string;
  phoneNumber: string;
}

export interface IPurchaseOrderDetailsCustomerAddress {
  houseNo?: string;
  street?: string;
  locality?: string;
  landmark?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  mapAddress?: string;
  addressType?: string;
  location: IPurchaseOrderDetailsCustomerAddressLocation;
  deliveryInstructions?: string;
  receiverDetails: IReceiverDetails;
}

export interface IPurchaseOrderDetailsDeliveryTimeSlot {
  date: string;
  time: string;
}
export interface IPurchaseOrderDetailsIPurchaseOrderDetails {
  [key: string]: {
    itemName: string;
    quantity: number;
    price: number;
    itemCategory: string;
    foodType: string;
    revenueSharingPercentage?: number;
  };
}

export interface IInitiatedCouponDetails {
  couponName: string;
  couponCode: string;
  remarks: string;
}

export interface IPurchaseOrderDetails {
  customerName: string;
  customerPhoneNumber: string;
  customerAddress: IPurchaseOrderDetailsCustomerAddress,
  parentZoneName: string;
  subZoneName: string;
  mealTime: string;
  orderType: string;
  deliveryTimeSlot: IPurchaseOrderDetailsDeliveryTimeSlot,
  orderedDateTime: string;
  purchaseOrderId: string;
  referenceId: string;
  customerId: string;
  orderStatus: PurchaseOrderStatuses;
  paymentId: string;
  createdBy: IOrderLogsCreatedBy,
  itemDetails: IPurchaseOrderDetailsIPurchaseOrderDetails,
  kitchenName: string;
  kitchenDetail?: string;
  specialInstructions?: string;
  specialInstructionAcknowledged?: string;
  cancellationReason?: string;
  deliveryInstructions?: string;
  productDetails: Array<IProductDetails>;
  orderCategory?: OrderCategoryEnum;
  productType?: ProductTypesEnum;
  relatedOrderCount?: number;
  simplifiedOrderId: string;
  initiatedCouponDetails: Array<IInitiatedCouponDetails>;
  deliveryTimeSlotMessage?: string;
  totalCoinOfferAvailedCount?: number;
  coinsAppliedToDeliveryFeeOffer?: number;
  coinsAppliedToHandlingFeeOffer?: number;
  coinsAppliedToPlatformFeeOffer?: number;
  coinsAppliedToSmallOrderFeeOffer?: number;
  coinBalance: {
    _id: string;
    customerId: string;
    unSettledCoins: number;
    createdAt: string;
    updatedAt: string;
    __v: number;
    availableCoins: number;
  };
  storeFrontProductDetails: Array<IProductDetails>;
}

export interface IPaymentDetailsBillDetails {
  netAmount: number;
  billedAmount: number;
  packagingCharge: number;
  sgst: number;
  cgst: number;
  roundOff: number;
  deliveryTipAmount: number;
  deliveryCharge?: number | null;
  gateway: string;
  gatewayOrderId: string;
}

export interface IPaymentGatewayOrderIds {
  gateway: string;
  gatewayOrderId: string;
}

export interface IBillPaymentDetails {
  netAmount?: number;
  sgst?: number;
  cgst?: number;
  igst?: number;
  roundOff?: number;
  billedAmount?: number;
  revisedSgst?: number;
  revisedCgst?: number;
  revisedIgst?: number;
  revisedRoundOff?: number;
  deliveryTipAmount?: number;
  deliveryCharge?: number | null;
  couponId?: string;
  couponName?: string;
  couponCode?: string;
  savingsOnDiscount?: number;
  platformFee?: number,
  platformFeeIgst?: number,
  platformFeeCgst?: number,
  platformFeeSgst?: number,
  smallOrderFee?: number,
  smallOrderFeeIgst?: number,
  smallOrderFeeCgst?: number,
  smallOrderFeeSgst?: number,
  packagingCharge?: number;
  packagingChargeIgst?: number;
  packagingChargeCgst?: number;
  packagingChargeSgst?: number;
}

export enum PaymentTypeEnum {
  PURCHASE = 'purchase',
  REFUND = 'refund'
}

export enum CurrencyEnum {
  INR = 'INR'
}

export enum PaymentModeEnum {
  ONLINE = 'online',
  CASH = 'cash'
}
export interface IPaymentDetails {
  email?: string;
  // With country code
  mobile: string;
  paymentType?: string;
  currency?: string;
  mode: string;
  billDetails?: IBillPaymentDetails;
  amount: number;
  status?: PaymentStatusEnum;
  paymentId?: string;
  gateway?: string;
  gatewayOrderId?: string;
  customerName?: string;
  isPaid?: boolean;
}

export interface IOrderLogsCreatedBy {
  entityId: string;
  entityType: string;
  entityName: string;
}

export interface ICreatedOrUpdatedBy {
  entityId: string;
  entityName: string;
  entityType: number;
}

export interface IIPurchaseOrderDetailsDataOrderLogs {
  purchaseOrderId: string;
  orderStatus: string;
  createdBy: IOrderLogsCreatedBy;
  createdAt: string;
  updatedAt: string;
  remarks?: string;
}

export interface IOrderNotes {
  notesId: string;
  orderId: string;
  referenceId: string;
  note: string;
  createdBy: ICreatedOrUpdatedBy;
  createdAt: string;
  updatedAt: string;
}

export interface IRatings {
  ratingId: string;
  reviewerType: string;
  revieweeType: string;
  reviewerId: string;
  revieweeId: string;
  rating: number;
  orderId: string;
  createdAt: string;
  updatedAt: string;
}

export interface ITransaction {
  gatewayTxnId?: string;
  paymentFailedReason?: string;
  txnId?: string;
  txnMethod?: string;
}

export interface IRefundCreatedBy {
  entityId: string;
  entityType: string;
  entityName: string;
}
export interface IPaymentRefunds {
  refundId?: string;
  paymentId: string;
  txnId: string;
  refundType: RzpRefundSpeedEnum;
  gatewayRefundId?: string;
  refundAmount: number;
  refundReason?: string;
  refundStatus?: RefundStatusEnum;
  gatewayResponse?: object;
  createdBy?: IRefundCreatedBy;
  createdAt: string;
}

export enum RzpRefundSpeedEnum {
  NORMAL = 'normal',
  OPTIMUM = 'optimum'
}

export enum RefundStatusEnum {
  REFUND_CREATED = 'created',
  REFUND_FAILED = 'failed',
  REFUND_PROCESSED = 'processed',
}
export interface IPurchaseOrderDetailsData {
  orderDetails: IPurchaseOrderDetails;
  payment: IPaymentDetails;
  orderLogs: Array<IIPurchaseOrderDetailsDataOrderLogs>;
  orderNotes: Array<IOrderNotes>;
  ratings: Array<IRating>;
  txn?: Array<ITransaction>;
  refundLogs?: Array<IPaymentRefunds>;
}

export interface IPurchaseOrderDetailsResponse {
  statusCode: number;
  timestamp: string;
  method: string;
  path: string;
  message: string;
  data: IPurchaseOrderDetailsData;
}

export interface ICookDeliveryTimeSlot {
  date: string;
  time: string;
}

export interface ILocation {
  type: string;
  coordinates: Array<number>;
}
export interface IKitchenAddress {
  line1Address?: string;
  line2Address?: string;
  line3Address?: string;
  pincode?: string;
  city?: string;
  state?: string;
  nearestLocation?: string;
  location?: ILocation;
}

export interface IProductDetails {
  productId: string;
  productName: string;
  name: string;
  foodType: string;
  sku: number;
  kitchenId: string;
  price: number;
  skuUOM: string;
  quantity: number;
  product: number;
  packingCharge: number;
  customization: any;
  customizationPrice?: number;
  productNetAmount?: number;
  productLogoImageUrl?: {
    url: string;
    expiresIn: number;
  };
  categoryName: string;
  storeFrontProductId: string;
  storeFrontBrandId: string;
  satelliteStoreId: string;
  brandName: string;
}

interface IAssets {
  s3FileName: string;
  originalFileName: string;
  contentType: string;
  fileType: string;
  fileUrl: {
    url: string;
    expiresIn: number;
  };
}

export interface ICookOrderDetails {
  cookName: string;
  cookMobileNumber: string;
  specialInstructions: null;
  itemDetails: IPurchaseOrderDetailsIPurchaseOrderDetails;
  orderStatus: ChefOrderStatuses;
  orderType: string;
  mealTime: string;
  orderedDateTime: string;
  deliveryTimeSlot: ICookDeliveryTimeSlot;
  pickUpDateTime?: string;
  parentZoneName: string;
  parentZoneId: string;
  subZoneName: string;
  subZoneId: string;
  createdBy: IOrderLogsCreatedBy;
  chefOrderId: string;
  referenceId: string;

  kitchenName?: string;
  specialInstructionAcknowledged?: boolean;
  kitchenAddress?: IKitchenAddress;
  cancelOrderDescription?: string;
  productDetails: Array<IProductDetails>;
  simplifiedOrderId: string;
  assets?: Array<IAssets>;
  deliveryTimeSlotMessage?: string;
  storeFrontProductDetails: Array<IProductDetails>;
  orderCategory?: string;
}

export interface ICookPayoutDetails {
  netAmount: number;
  totalPayment: number;
  couponId: string;
  discount: number;
  couponName: string;
  couponCode: string;
  couponCreatedFor: string;
  platformCommission: {
    totalNetAmount: number;
    totalPayment: number;
    taxPercentage: number;
    sgst: number;
    cgst: number;
    igst: number;
  };
  payoutAmount?: number;
  taxAmount?: number;
}

export interface ICookOrderLogs {
  _id: string;
  chefOrderId: string;
  orderStatus: string;
  createdBy: IOrderLogsCreatedBy;
  createdAt: string;
  updatedAt: string;
  remarks?: string;
  __v: number;
}

export interface ICookOrderNotes {
  _id: string;
  notesId: string;
  orderId: string;
  referenceId: string;
  note: string;
  createdAt: string;
  createdBy: ICreatedOrUpdatedBy;
  updatedAt: string;
  __v: number;
}

export interface ISatelliteStoreDetails {
  satelliteStoreAddress: string;
  satelliteStoreContactName: string;
  satelliteStoreContactNumber: string;
  satelliteStoreId: string;
  satelliteStoreName: string;
}
export interface ICookOrderDetailsData {
  orderDetails: ICookOrderDetails;
  payoutDetails: ICookPayoutDetails;
  orderLogs: ICookOrderLogs[];
  orderNotes: ICookOrderNotes[];
  satelliteStoreDetails?: ISatelliteStoreDetails;
}
export interface ICookOrderDetailsResponse {
  statusCode: number;
  timestamp: string;
  method: string;
  path: string;
  message: string;
  data: ICookOrderDetailsData;
}

export interface IPickUpOrDropDetails {
  contactPersonName: string;
  contactPersonNumber: string;
  address: string,
  location: ILocation;
}

export interface ILogisticDetailForCallMasking {
  logisticOrderId: string,
  riderName: string,
  riderMobileNumber: string,
  riderId: string,
  tplOrderId: string;
  trackingUrl: string;
}
export interface IRiderOrderDetails {
  logisticOrderId?: string;
  referenceId?: string;
  orderType?: string;
  orderStatus: LogisticOrderStatus;
  mealTime?: string;
  pickUpDetails: IPickUpOrDropDetails;
  dropDetails: IPickUpOrDropDetails;
  deliveryTip?: number;
  deliveryTimeSlot?: ICookDeliveryTimeSlot;
  deliveryInstructions?: string;
  amountToBeCollected?: number;
  paymentMode?: string;
  tplOrderId?: string;
  riderType?: string;
  riderId?: string;
  createdBy?: IOrderLogsCreatedBy;
  parentZoneId?: string;
  parentZoneName?: string;
  subZoneId?: string;
  subZoneName?: string;
  riderName?: string,
  vendorName?: string,
  riderMobileNumber?: string,
  trackingUrl?: string;
  firstMileDistance?: number;
  lastMileDistance?: number;
  simplifiedOrderId?: string;
  deliveryTimeSlotMessage?: string;
}
export interface IRiderOrderLogs {
  _id: string;
  logisticOrderId: string;
  orderStatus: string;
  createdBy: IOrderLogsCreatedBy;
  createdAt: string;
  updatedAt: string;
  distance?: number;
  duration?: number;
  remarks?: string;
  __v: number;
}
export interface ITPLOrderRiderOrderDetailsData {
  parentZoneName: string;
  radiusInKms: number;
  firstMileDistance: number;
  lastMileDistance: number;
  isTplPreferable: boolean;
}
export interface IRiderOrderDetailsData {
  orderDetails: IRiderOrderDetails;
  orderLogs: IRiderOrderLogs[];
  orderNotes: IOrderNotes[];
  tplOrderLogs: any;
  tplOrder?: ITPLOrderRiderOrderDetailsData;
}
export interface IRiderOrderDetailsResponse {
  statusCode: number;
  timestamp: string;
  method: string;
  path: string;
  message: string;
  data: IRiderOrderDetailsData;
}
export interface IChefOrderItemDetails {
  itemId: string;
  quantity: number;
  price: number;
  itemName: string;
  itemCategory: string;
  foodType: string;
  revenueSharingPercentage: number;
  description?: string;
}

export interface IChefOrder {
  referenceId: string;
  chefOrderId: string;
  kitchenId: string;
  orderStatus: ChefOrderStatuses;
  orderType: OrderTypes;
  isInstantDeliveryOrder?: boolean;
  mealTime: MealTimes;
  createdAt: string;
  orderedDateTime: Date;
  deliveryTimeSlot: {
    date: string;
    time: string;
  },
  itemDetails: Record<string, IChefOrderItemDetails>;
  netAmount: number;
  totalPayment?: number;
  payoutAmount?: number;
  couponId?: string;
  discount?: number;
  parentZoneId: string;
  subZoneId: string;
  specialInstructions?: string;
  specialInstructionAcknowledged?: boolean;
  isOrderNoteExists: boolean;
  cookName: string;
  isProductExists: boolean;
  kitchenRating: IRating;
  productRating: IRating;
  cancelOrderDescription?: string;
  createdBy: ICreatedOrUpdatedBy;
  class?: string;
  orderStatusLabel?: string;
  ratingStarAssetLink?: string;
  kitchenName: string;
  preOrder?: boolean;
  productType?: string;
  relatedOrderCount?: number;
  simplifiedOrderId?: string;
  satelliteStoreId?: string;
  isFoodReady?: boolean;
  deliveryTimeSlotMessage?: string;
  checked?: boolean;
}

interface IChefOrderDetails {
  data: {
    chefOrders: IChefOrder[],
    chefOrdersCount: number,
  };
}

@Injectable()
export class OrderApiService extends RestApiService {
  // TODO: update this end point
  protected override baseApiUrl = environment.config['KITCHEN_SERVICE_URL'];

  protected orderServiceApiUrl = environment.config['ORDER_SERVICE_URL'];

  protected customerApiUrl = environment.config['CUSTOMER_SERVICE_URL'];

  protected cookApiUrl = environment.config['COOK_SERVICE_URI'];

  protected riderApiUrl = environment.config['LOGISTIC_SERVICE_URL'];

  protected adminBaseApiUrl = environment.config['ADMIN_SERVICE_URL'];

  protected paymentApiUrl = environment.config['PAYMENT_SERVICE_URI'];

  protected configServiceBaseApiUrl = environment.config['CONFIG_SERVICE_URL'];

  private servicePath = `${this.baseApiUrl}/orders`;

  private customerPath = `${this.customerApiUrl}/orders/detail/reference`;

  private cookPath = `${this.cookApiUrl}/chef-orders/detail/reference`;

  private riderPath = `${this.riderApiUrl}/logistic-orders/detail/reference`;

  private ordersApiUrl = `${this.adminBaseApiUrl}/control-tower/orders`;

  private adminServicePath = `${this.adminBaseApiUrl}/orders`;

  protected chefOrdersBaseApiUrl = environment.config['COOK_SERVICE_URI'];

  private chefOrdersServicePath = `${this.chefOrdersBaseApiUrl}/common/chef-orders`;

  private brandPayoutServicePath = `${this.chefOrdersBaseApiUrl}/common/storefront-payouts/brands`;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  async getChefOrders(queryParams?: Record<any, (string | number | boolean)>): Promise<IChefOrderDetails> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['kitchenId']) {
        searchParams.set('kitchenId', String(queryParams['kitchenId']));
      }
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if (queryParams['skip'] !== undefined) {
        searchParams.set('skip', String(queryParams['skip']));
      }
      if (queryParams['mealTime'] !== undefined) {
        if (Array.isArray(queryParams['mealTime'])) {
          queryParams['mealTime'].forEach((item) => {
            searchParams.append('mealTime', item);
          });
        }
      }
      if (Array.isArray(queryParams['orderStatus'])) {
        queryParams['orderStatus'].forEach((item) => {
          searchParams.append('orderStatus', item);
        });
      }
      if (Array.isArray(queryParams['orderType'])) {
        queryParams['orderType'].forEach((item) => {
          searchParams.append('orderType', item);
        });
      }
      if (queryParams['sendAllOrders'] !== undefined) {
        searchParams.set('sendAllOrders', String(queryParams['sendAllOrders']));
      }
      if (Array.isArray(queryParams['parentZoneId'])) {
        queryParams['parentZoneId'].forEach((item) => {
          searchParams.append('parentZoneId', item);
        });
      } else if (queryParams['parentZoneId'] !== undefined) {
        searchParams.append('parentZoneId', String(queryParams['parentZoneId']));
      }
      if (queryParams['subZoneId'] !== undefined) {
        searchParams.set('subZoneId', String(queryParams['subZoneId']));
      }
      if (queryParams['startDate'] !== undefined) {
        searchParams.set('startDate', String(queryParams['startDate']));
      }
      if (queryParams['endDate'] !== undefined) {
        searchParams.set('endDate', String(queryParams['endDate']));
      }
      if (queryParams?.['search']) {
        searchParams.set('search', String(queryParams['search']));
      }
      if (queryParams?.['orderCategory']) {
        searchParams.set('orderCategory', String(queryParams['orderCategory']));
      }
      if (queryParams['orderTabType']) {
        searchParams.set('orderTabType', String(queryParams['orderTabType']));
      }
    }

    const apiPath = `${this.chefOrdersServicePath}?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getOrder(id: string) {
    const apiPath = `${this.ordersApiUrl}/${id}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getOrderDetails(id: string) {
    const apiPath = `${this.customerPath}/${id}`;
    return this.httpGet(apiPath);
  }

  async getCookDetails(id: string, chefOrderId: string = '') {
    const searchParams = new URLSearchParams();
    if (chefOrderId) {
      searchParams.set('chefOrderId', String(chefOrderId));
    }
    const apiPath = `${this.cookPath}/${id}?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  async getRiderDetails(id: string) {
    const apiPath = `${this.riderPath}/${id}`;
    return this.httpGet(apiPath);
  }

  async updateChefPartiallyCompleteCompletedStatus(referenceId: string, remarks: string, orderId: string) {
    const payload = {
      remarks,
    };
    const apiPath = `${this.chefOrdersServicePath}/${orderId}/partially-full-filled?referenceId=${referenceId}`;
    return this.httpPut(apiPath, payload);
  }

  async updateChefInCompletedStatus(referenceId: string, remarks: string, orderId: string) {
    const payload = {
      remarks,
    };
    const apiPath = `${this.chefOrdersServicePath}/${orderId}/incomplete?referenceId=${referenceId}`;
    return this.httpPut(apiPath, payload);
  }

  async updateChefPreparingStatus(referenceId: string) {
    const apiPath = `${this.chefOrdersServicePath}/seller-products/order-status/preparing/referenceId/${referenceId}`;
    return this.httpPut(apiPath);
  }

  async updateChefOrderToPreparing(chefOrderId: string, queryParams?: Record<any, (string | number | boolean)>) {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['orderStatus']) {
        searchParams.set('orderStatus', String(queryParams['orderStatus']));
      }
      searchParams.set('specialInstructionAcknowledged', String(queryParams['specialInstructionAcknowledged']));
    }
    const apiPath = `${this.chefOrdersBaseApiUrl}/chef-orders/${chefOrderId}?${searchParams.toString()}`;
    return this.httpPut(apiPath);
  }

  async get(id: string) {
    const apiPath = `${this.orderServiceApiUrl}/${id}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getAll(queryParams?: Record<any, (string | number)>): Promise<{
    orders: IOrderResponse[],
    totalPages: number,
    totalOrders: number,
    totalRefundedOrders: number,
    totalCancelledOrders: number;
  }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['kitchenId']) {
        searchParams.set('kitchenId', String(queryParams['kitchenId']));
      }
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if (queryParams['skip'] !== undefined) {
        searchParams.set('skip', String(queryParams['skip']));
      }
      if (queryParams['date'] !== undefined) {
        searchParams.set('date', String(queryParams['date']));
      }
      if (queryParams['mealType'] !== undefined) {
        if (Array.isArray(queryParams['mealType'])) {
          queryParams['mealType'].forEach((item) => {
            searchParams.append('mealType', item);
          });
        }
      }
      if (queryParams['paymentStatus'] !== undefined) {
        if (Array.isArray(queryParams['paymentStatus'])) {
          queryParams['paymentStatus'].forEach((item) => {
            searchParams.append('paymentStatus', item);
          });
        }
      }
      if (Array.isArray(queryParams['status'])) {
        queryParams['status'].forEach((item) => {
          searchParams.append('status', item);
        });
      }

      if (Array.isArray(queryParams['parentZoneId'])) {
        queryParams['parentZoneId'].forEach((item) => {
          searchParams.append('parentZoneId', item);
        });
      } else if (queryParams['parentZoneId'] !== undefined) {
        searchParams.append('parentZoneId', String(queryParams['parentZoneId']));
      }
      if (queryParams['subZoneId'] !== undefined) {
        searchParams.set('subZoneId', String(queryParams['subZoneId']));
      }
      if (queryParams['startDate'] !== undefined) {
        searchParams.set('startDate', String(queryParams['startDate']));
      }
      if (queryParams['endDate'] !== undefined) {
        searchParams.set('endDate', String(queryParams['endDate']));
      }
      if (queryParams['paymentType'] !== undefined) {
        if (Array.isArray(queryParams['paymentType']) && queryParams['paymentType'].length !== 2) {
          queryParams['paymentType'].forEach((item) => {
            searchParams.append('paymentType', item);
          });
        }
      }
    }

    const apiPath = `${this.adminServicePath}?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async fetchAll(queryParams?: Record<any, (string | number | boolean)>): Promise<{
    orders: IOrderResponse[],
    totalPages: number,
    totalOrders: number,
    totalRefundedOrders: number,
    totalCancelledOrders: number;
  }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['isOrdersHistory']) {
        searchParams.set('isOrdersHistory', String(queryParams['isOrdersHistory']));
      }
      if (queryParams['kitchenId']) {
        searchParams.set('kitchenId', String(queryParams['kitchenId']));
      }
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if (queryParams['skip'] !== undefined) {
        searchParams.set('skip', String(queryParams['skip']));
      }
      if (queryParams['date'] !== undefined) {
        searchParams.set('date', String(queryParams['date']));
      }
      if (queryParams['allOrders'] !== undefined) {
        searchParams.set('allOrders', String(queryParams['allOrders']));
      }
      if (queryParams['mealType'] !== undefined) {
        if (Array.isArray(queryParams['mealType'])) {
          queryParams['mealType'].forEach((item) => {
            searchParams.append('mealType', item);
          });
        }
      }
      if (queryParams['paymentStatus'] !== undefined) {
        if (Array.isArray(queryParams['paymentStatus'])) {
          queryParams['paymentStatus'].forEach((item) => {
            searchParams.append('paymentStatus', item);
          });
        }
      }
      if (Array.isArray(queryParams['status'])) {
        queryParams['status'].forEach((item) => {
          searchParams.append('status', item);
        });
      }

      if (Array.isArray(queryParams['parentZoneId'])) {
        queryParams['parentZoneId'].forEach((item) => {
          searchParams.append('parentZoneId', item);
        });
      } else if (queryParams['parentZoneId'] !== undefined) {
        searchParams.append('parentZoneId', String(queryParams['parentZoneId']));
      }
      if (queryParams['subZoneId'] !== undefined) {
        searchParams.set('subZoneId', String(queryParams['subZoneId']));
      }
      if (queryParams['startDate'] !== undefined) {
        searchParams.set('startDate', String(queryParams['startDate']));
      }
      if (queryParams['endDate'] !== undefined) {
        searchParams.set('endDate', String(queryParams['endDate']));
      }
      if (queryParams['paymentType'] !== undefined) {
        if (Array.isArray(queryParams['paymentType']) && queryParams['paymentType'].length !== 2) {
          queryParams['paymentType'].forEach((item) => {
            searchParams.append('paymentType', item);
          });
        }
      }
      if (queryParams['search'] !== undefined) {
        searchParams.set('search', String(queryParams['search']));
      }
    }

    const apiPath = `${this.adminServicePath}/order-history?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async fetchTodaysOrder(queryParams?: Record<any, (string | number | boolean)>): Promise<{
    orders: IOrderResponse[],
    totalPages: number,
    totalOrders: number,
    totalRefundedOrders: number,
    totalCancelledOrders: number;
  }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['kitchenId']) {
        searchParams.set('kitchenId', String(queryParams['kitchenId']));
      }
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if (queryParams['skip'] !== undefined) {
        searchParams.set('skip', String(queryParams['skip']));
      }
      if (queryParams['allOrders'] !== undefined) {
        searchParams.set('allOrders', String(queryParams['allOrders']));
      }
      if (queryParams['mealType'] !== undefined) {
        if (Array.isArray(queryParams['mealType'])) {
          queryParams['mealType'].forEach((item) => {
            searchParams.append('mealType', item);
          });
        }
      }
      if (queryParams['paymentStatus'] !== undefined) {
        if (Array.isArray(queryParams['paymentStatus'])) {
          queryParams['paymentStatus'].forEach((item) => {
            searchParams.append('paymentStatus', item);
          });
        }
      }
      if (Array.isArray(queryParams['status'])) {
        queryParams['status'].forEach((item) => {
          searchParams.append('status', item);
        });
      }

      if (Array.isArray(queryParams['parentZoneId'])) {
        queryParams['parentZoneId'].forEach((item) => {
          searchParams.append('parentZoneId', item);
        });
      } else if (queryParams['parentZoneId'] !== undefined) {
        searchParams.append('parentZoneId', String(queryParams['parentZoneId']));
      }
      if (queryParams['subZoneId'] !== undefined) {
        searchParams.set('subZoneId', String(queryParams['subZoneId']));
      }
      if (queryParams['paymentType'] !== undefined) {
        if (Array.isArray(queryParams['paymentType']) && queryParams['paymentType'].length !== 2) {
          queryParams['paymentType'].forEach((item) => {
            searchParams.append('paymentType', item);
          });
        }
      }
      if (queryParams['search'] !== undefined) {
        searchParams.set('search', String(queryParams['search']));
      }
    }

    const apiPath = `${this.adminServicePath}/today?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async create(payload: object) {
    const apiPath = this.servicePath;
    return this.httpPost(apiPath, payload);
  }

  async customerFirstOrder(payload: { listOfOrdersToCheck: IListOfOrdersToCheck[]; }): Promise<IListOfOrdersToCheck[]> {
    const apiPath = `${this.adminServicePath}/customers-first-order`;
    return this.httpPost(apiPath, payload);
  }

  async delete(id: string) {
    const apiPath = `${this.servicePath}${id}`;
    return this.httpDelete(apiPath);
  }

  async update(id: string, payload: object) {
    const apiPath = `${this.servicePath}${id}`;
    return this.httpPut(apiPath, payload);
  }

  async getNewDashboardOrderMetrics(queryParams: IGetDashboardOrderMetricsInput): Promise<INewDashboardMetricsResponse> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      searchParams.set('fromDate', queryParams['fromDate']);
      searchParams.set('toDate', queryParams['toDate']);
    }
    if (queryParams['parentZoneId']) {
      searchParams.set('parentZoneId', queryParams['parentZoneId']);
    }
    const apiPath = `${this.servicePath}/dashboard/metrics?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getDashboardOrderMetrics(queryParams: IGetDashboardOrderMetricsInput): Promise<IGetDashboardOrderMetricsResponse> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      searchParams.set('fromDate', queryParams['fromDate']);
      searchParams.set('toDate', queryParams['toDate']);
    }
    const apiPath = `${this.servicePath}/dashboard?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async updateOrderStatus(id: string, orderStatus: string, cancelOrderDescription?: string) {
    const searchParams = new URLSearchParams();
    searchParams.set('orderStatus', orderStatus);
    const body: any = {};
    if (cancelOrderDescription) {
      body.cancelOrderDescription = cancelOrderDescription;
    }
    const apiPath = `${this.servicePath}/order-status/${id}?${searchParams.toString()}`;
    return this.httpPut(apiPath, body);
  }

  async addNotes(orderId: string, referenceId: string, note?: string) {
    const payload = {
      orderId,
      referenceId,
      note,
    };
    const apiPath = `${this.orderServiceApiUrl}/order-notes`;
    return this.httpPost(apiPath, payload);
  }

  async getRefundDetails(orderId: string): Promise<IRefundResponse> {
    const apiPath = `${this.adminServicePath}/refund-info/${orderId}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async refundOrder(paymentId: string, {
    totalRefund,
    refundOption,
    refundReason,
  }: {
    totalRefund: number,
    refundOption: RefundOptionTypes,
    refundReason: string,
  }) {
    const payload = {
      refundAmount: totalRefund,
      refundType: refundOption,
      refundReason,
    };
    const apiPath = `${this.paymentApiUrl}/payments/${paymentId}/initiate-refund`;
    return this.httpPost(apiPath, payload);
  }

  async getOrderLogs(orderId: string): Promise<IOrderLog[]> {
    const apiPath = `${this.servicePath}/order-logs/${orderId}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async assignRider({ orderId, riderId }: { orderId: string, riderId: string; }): Promise<IOrderResponse> {
    const apiPath = `${this.servicePath}/custom-rider-order/${riderId}`;
    return this.httpPut(apiPath, {
      orderId,
    });
  }

  async getKitchensOrders(queryParams?: Record<any, (string | number)>) {
    const searchParams = new URLSearchParams();
    let path;
    const check = isEmpty(queryParams);
    if (!check) {
      if (queryParams?.['mealTime'] !== undefined) {
        if (Array.isArray(queryParams['mealTime'])) {
          queryParams['mealTime'].forEach((item) => {
            searchParams.append('mealTime', item);
          });
        }
      }
      if (Array.isArray(queryParams?.['parentZoneId'])) {
        queryParams?.['parentZoneId'].forEach((item) => {
          searchParams.append('parentZoneId', item);
        });
      }
      if (queryParams?.['subZoneId'] !== undefined) {
        searchParams.set('subZoneId', String(queryParams['subZoneId']));
      }
      if (queryParams?.['ordersCount'] !== undefined) {
        searchParams.set('ordersCount', String(queryParams['ordersCount']));
      }
      path = `${this.adminBaseApiUrl}/cooks/rush-in-kitchens/in-progress-orders?${searchParams.toString()}`;
    } else {
      path = `${this.adminBaseApiUrl}/cooks/rush-in-kitchens/in-progress-orders`;
    }
    const apiPath = path;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  // Brand Orders API's in Payout tab
  async getBrandOrdersById(storeFrontBrandId: string, queryParams?: Record<any, (string | number | boolean)>): Promise<IChefOrderDetails> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if (queryParams['skip'] !== undefined) {
        searchParams.set('skip', String(queryParams['skip']));
      }
      if (queryParams['startDate'] !== undefined) {
        searchParams.set('startDate', String(queryParams['startDate']));
      }
      if (queryParams['endDate'] !== undefined) {
        searchParams.set('endDate', String(queryParams['endDate']));
      }
      if (queryParams?.['search']) {
        searchParams.set('search', String(queryParams['search']));
      }
    }
    const apiPath = `${this.brandPayoutServicePath}/${storeFrontBrandId}/eligible/chef-orders?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }
}
