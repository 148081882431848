<div class="theme-banner-modal container-fluid mt-4 mb-3 ">
  <header>
    <h4 class="header-text m-0" *ngIf="manipulationType === manipulationTypes.ADD">Add Theme</h4>
    <div class="header-text text-capitalize" *ngIf="manipulationType === manipulationTypes.EDIT">
      <h4 class="d-inline-block m-0 me-2">Edit Theme</h4>
      <span>#{{modalConfig?.data?.theme?.bannerId}}</span>
    </div>
    <button class="close-btn">
      <img src="assets/icon-order-status-close.svg" alt="icon-close" (click)="modalRef.close();">
    </button>
  </header>
  <form [formGroup]="form" [ngClass]="{'submitted':formSubmitted}">
    <div class="row m-0 p-0 mb-4">
      <div class="col-md-6 ps-0"> <!-- Theme Banner Name Input-->
        <div class="formInput mt-3">
          <label class="labelFont required">Name</label>
          <input tabindex="0" type="text" class="form-control inputBox" formControlName="title"
            placeholder="Enter a name" />
        </div>
      </div>

      <div class="col-md-6 pe-0"> <!-- Theme Banner Parent Zone Dropdown-->
        <div class="formInput mt-3">
          <label class="labelFont required mb-1">Select Parent Zone</label>
          <select tabindex="0" id="type" class="form-control inputBox" formControlName="parentZoneId"
            (change)="onParentZoneSelect()">
            <option value="">Select Parent Zone</option>
            <option *ngFor="let zone of parentZonalDetails" value={{zone.parentZoneId}}>{{zone.parentZoneName}}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="row p-0 m-0 mb-4"> <!-- Theme Banner Description Input-->
      <div class="col-md-12 p-0">
        <div class="formInput">
          <label class="labelFont required">Description</label>
          <textarea placeholder="Write Description" tabindex="0" type="text" class="form-control inputBox"
            formControlName="description" rows="4"></textarea>
        </div>
      </div>
    </div>

    <div class="row p-0 m-0 mb-4"> <!-- Theme Banner Image Upload Input-->
      <div class="col-md-12 p-0">
        <div class="formInput">
          <label class="labelFont required">Upload an image</label>
          <div class="upload-wrapper">
            <div class="banner-upload cursor-pointer p-2" appDropZone (onFileDrop)="onImageDrop($event)"
              [ngClass]="{'submitted': !bannerUploadImage}" (click)="bannerFile.click()">
              <div class="hoverImage" *ngIf="bannerUploadImage"></div>
              <input type="file" name="bannerPic" #bannerFile class="banner-file" formControlName="banner"
                accept="image/jpeg,image/png,image/jpg,image/gif" (change)="onImageUpload($event)" id="banner-upload" />
              <div class="d-flex flex-column justify-content-center" *ngIf="!bannerUploadImage">
                <img class="upload-icon" src="assets/icon-logo-upload-placeholder.svg">
                <p class="uploadTitle d-flex justify-content-center mt-1">Drag & Drop a PNG, JPG, JPEG or GIF File here
                </p>
                <label class="button">
                  <p class="mb-0 py-2">Select From Computer</p>
                </label>
              </div>
              <img [src]="bannerUploadImage?.url" alt="coverPic" class="uploaded-image"
                [hidden]="!bannerUploadImage?.url">
            </div>
            <button class="delete-item-cta mb-3 me-3" *ngIf="bannerUploadImage?.url"
              (click)="removeUploadedFile($event)">
              <img src="/assets/icon-delete-item.svg" alt="icon-delete-banner">
            </button>
          </div>
          <div *ngIf="modalConfig.data.adminType!=='brand'" class="banner-size-info">Supported aspect ratio: 180:157 -
            Eg: ( Width 360 , Height 314 )</div>
          <div *ngIf="modalConfig.data.adminType==='brand'" class="banner-size-info">Supported aspect ratio: 3:5 - Eg: (
            Width 360 , Height 600 )</div>
        </div>
      </div>
    </div>

    <div class="row p-0 m-0 mb-4"> <!-- Theme Banner Date & Status Inputs -->
      <div class="col-md-12 p-0">
        <div class="formInput">
          <label class="labelFont required mb-1">Select Date and Status</label>
          <div class="d-flex align-items-center flex-wrap gap-2">
            <div class="select-date">
              <span class="me-2">From</span>
              <mat-form-field class="example-full-width" appearance="fill">
                <input matInput [matDatepicker]="effectiveFrom" id="effectiveFrom" formControlName="effectiveFrom"
                  [min]="minDate" [max]="form.getRawValue().expireAfter" placeholder="MM/DD/YYYY">
                <mat-datepicker #effectiveFrom disabled="false"></mat-datepicker>
              </mat-form-field>
              <img class="ms-2" src="/assets/calendar-icon.png" alt="from-date" (click)="effectiveFrom.open()">
            </div>
            <div class="select-date">
              <span class="me-2">To</span>
              <mat-form-field class="example-full-width" appearance="fill">
                <input matInput [matDatepicker]="expireAfter" id="expireAfter" formControlName="expireAfter"
                  [min]="form.getRawValue().effectiveFrom" placeholder="MM/DD/YYYY">
                <mat-datepicker #expireAfter disabled="false"></mat-datepicker>
              </mat-form-field>
              <img class="ms-2" src="/assets/calendar-icon.png" alt="to-date" (click)="expireAfter.open()">
            </div>
            <div>
              <select class="form-control status-inputBox" formControlName="bannerStatus"
                (change)="onSelectStatus($event)">
                <option value="">Select Status</option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="formInput mb-3" *ngIf="modalConfig.data.sellerType==='foodItems'">
      <div class="portion-table-pureveg d-flex">
        <div class="toggle-pureveg">
          <input type="checkbox" class="me-1 ml-2" id="Pureveg" formControlName="restrictForPureVeg" />
          <label for="Pureveg">
            Restrict For Pure Veg
          </label>
          <img class="pureveg-icon" src="/assets/icon-pure-veg.svg" alt="icon-pure-veg">
        </div>
      </div>
    </div>
    <div class="row p-0 m-0" *ngIf="deepLinkRedirections.length>0 && modalConfig.data.adminType!=='brand'">
      <!-- Theme Banner Redirection Input-->
      <div class="col-md-6 ps-0">
        <div class="formInput">
          <label class="labelFont mb-1">Select a Redirection Type</label>
          <ng-select placeholder="Select a redirection type" class="custom" formControlName="redirectionType">
            <ng-option [value]="redirectionType" *ngFor="let redirectionType of deepLinkRedirections">
              {{getDeepLinkRedirectionType(redirectionType)}}
            </ng-option>
            <ng-option *ngIf="deepLinkRedirections.length" value="others">Others</ng-option>
          </ng-select>
        </div>
      </div>
      <div class="col-md-6 pe-0">
        <div class="formInput" *ngIf="form.value.redirectionType && form.value.redirectionType !== 'others'">
          <label class="labelFont required mb-1">Select a
            {{getDeepLinkRedirectionType(form.value.redirectionType)}}</label>
          <ng-select [placeholder]="'Select a '+ getDeepLinkRedirectionType(form.value.redirectionType)"
            class="custom mb-4" (search)="onSearchCategory($event.term)" (close)="onClose()" (clear)="onClose()"
            formControlName="redirectionId" (scrollToEnd)="fetchCategory()"
            *ngIf="form.value.redirectionType === 'special-category'">
            <ng-option [value]="category.specialCategoryId" *ngFor="let category of allCategory">
              <div class="d-flex justify-content-between">
                <span><img [src]="category?.specialCategoryImageUrl?.url" alt=""
                    *ngIf="category?.specialCategoryImageUrl"
                    class="special-tag-image" />{{category.specialCategoryName}}</span>
                <span class="tag-alignment">#{{category.specialCategoryId | slice:0:6}}</span>
              </div>
            </ng-option>
          </ng-select>
          <ng-select [placeholder]="'Select a '+ getDeepLinkRedirectionType(form.value.redirectionType)"
            class="custom mb-4" (search)="onSearchCoupon($event.term)" (close)="onClose()" (clear)="onClose()"
            formControlName="redirectionId" (scrollToEnd)="fetchCoupons()"
            *ngIf="form.value.redirectionType === 'coupon-description'">
            <ng-option [value]="coupon.couponId" *ngFor="let coupon of coupons">
              <div class="d-flex justify-content-between">
                <!-- <span class="me-2" style="max-width: 50%;word-break: break-all;">{{coupon.couponName}}</span> -->
                <span>{{coupon.couponCode}}</span>
              </div>
            </ng-option>
          </ng-select>
          <ng-select [placeholder]="'Select a '+ getDeepLinkRedirectionType(form.value.redirectionType)"
            class="custom mb-4" (search)="onSearchKitchen($event.term)" (close)="onClose()" (clear)="onClose()"
            formControlName="redirectionId" (scrollToEnd)="fetchKitchens()"
            *ngIf="form.value.redirectionType === 'kitchen'">
            <ng-option [value]="kitchen.kitchenId" *ngFor="let kitchen of kitchens">
              {{ kitchen.kitchenName }}
            </ng-option>
          </ng-select>
        </div>
      </div>
    </div>

    <div class="row p-0 m-0 mb-5" *ngIf="form.get('redirectionType')?.value === 'others' || generatedDeepLink.length">
      <!-- Theme Banner Deeplink Input-->
      <div class="col-md-12 p-0">
        <div class="formInput">
          <label class="labelFont mb-1">{{ form.get('redirectionType')?.value === 'others' ?
            'Paste a custom deep link' : 'Generated deep link' }}</label>
          <input tabindex="0" type="text" class="form-control inputBox" formControlName="deepLinkUrl" />
        </div>
      </div>
    </div>

    <!-- Status Bar Text Color -->
    <div class="row p-0 m-0 mb-4" *ngIf="modalConfig.data.sellerType === 'storeFrontSeller'">
      <div class="col-md-12 p-0">
        <div class="formInput">
          <label class="labelFont required mb-1">Select Bar Text Color</label>
          <div class="d-flex align-items-center flex-wrap gap-2">
            <div>
              <label class="labelFont m-0 checkbox-container">Default Text Color</label>
              <select class="form-control status-inputBox" formControlName="statusBarDefaultTextColor">
                <option value="">Default Text Color</option>
                <option [value]="textColorConfigEnum.LIGHT_CONTENT">Light Content</option>
                <option [value]="textColorConfigEnum.DARK_CONTENT">Dark Content</option>
              </select>
            </div>
            <div>
              <label class="labelFont m-0 checkbox-container">Sticky Text Color</label>
              <select class="form-control status-inputBox" formControlName="statusBarStickyTextColor">
                <option value="">Sticky Text Color</option>
                <option [value]="textColorConfigEnum.LIGHT_CONTENT">Light Content</option>
                <option [value]="textColorConfigEnum.DARK_CONTENT">Dark Content</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Status Bar Content Color -->
    <div class="row p-0 m-0 mb-4" *ngIf="modalConfig.data.sellerType === 'storeFrontSeller'">
      <div class="col-md-12 p-0">
        <div class="formInput">
          <label class="labelFont required mb-1">Select Bar Content Color</label>
          <div class="d-flex flex-column flex-wrap gap-2">
            <div class="d-flex align-items-center gap-2">
              <label class="labelFont m-0 checkbox-container">Default Content Color</label>
              <div class="toggle-checkbox">
                <input type="checkbox" class="me-1 ml-2" id="default-transparent"
                  formControlName="isDefaultContentTransparent" (change)="onChangeDefaultTransparentColor($event)" />
                <label class="labelFont me-2 mb-0" for="default-transparent">
                  Transparent
                </label>
                <input *ngIf="!form.value.isDefaultContentTransparent" formControlName="statusBarDefaultContentColor"
                  [value]="defaultColorList[0].value" [style.background]="defaultColorList[0].value"
                  [(colorPicker)]="defaultColorList[0].value" [cpOutputFormat]="'hex'" [cpPosition]="'left'"
                  style="border: '1px solid #cfd0d7'; padding: 5px 10px; border-radius: 6px;"
                  (colorPickerChange)="onDefaultContentColorChange($event)" />
              </div>
            </div>
            <div class="d-flex align-items-center gap-2">
              <label class="labelFont m-0 checkbox-container">Sticky Content Color</label>
              <div class="toggle-checkbox">
                <input type="checkbox" class="me-1 ml-2" id="sticky-transparent"
                  formControlName="isStickyContentTransparent" (change)="onChangeStickyTransparentColor($event)" />
                <label class="labelFont me-2 mb-0" for="sticky-transparent">
                  Transparent
                </label>
                <input *ngIf="!form.value.isStickyContentTransparent" formControlName="statusBarStickyContentColor"
                  [value]="stickyColorList[0].value" [style.background]="stickyColorList[0].value"
                  [(colorPicker)]="stickyColorList[0].value" [cpOutputFormat]="'hex'" [cpPosition]="'left'"
                  style="border: '1px solid #cfd0d7'; padding: 5px 10px; border-radius: 6px;"
                  (colorPickerChange)="onStickyContentColorChange($event)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Delivery Bar Color -->
    <div class="row p-0 m-0 mb-4" *ngIf="modalConfig.data.sellerType === 'storeFrontSeller'">
      <div class="col-md-12 p-0">
        <div class="formInput">
          <label class="labelFont required mb-1">Select Delivery Bar Color</label>
          <div class="d-flex flex-column flex-wrap gap-2">
            <div class="d-flex align-items-center gap-2">
              <label class="labelFont m-0 checkbox-container">Text Color</label>
              <div class="toggle-checkbox">
                <input type="checkbox" class="me-1 ml-2" id="text-transparent"
                  formControlName="isDeliveryTextColorTransparent"
                  (change)="onChangeDeliveryBarTextTransparentColor($event)" />
                <label class="labelFont me-2 mb-0" for="text-transparent">
                  Transparent
                </label>
                <input *ngIf="!form.value.isDeliveryTextColorTransparent" formControlName="deliveryBarTextColor"
                  [value]="deliveryBarTextColorList[0].value" [style.background]="deliveryBarTextColorList[0].value"
                  [(colorPicker)]="deliveryBarTextColorList[0].value" [cpOutputFormat]="'hex'" [cpPosition]="'left'"
                  style="border: '1px solid #cfd0d7'; padding: 5px 10px; border-radius: 6px;"
                  (colorPickerChange)="onDeliveryBarTextColorChange($event)" />
              </div>
            </div>
            <div class="d-flex align-items-center gap-2">
              <label class="labelFont m-0 checkbox-container">Background Color</label>
              <div class="toggle-checkbox">
                <input type="checkbox" class="me-1 ml-2" id="background-transparent"
                  formControlName="isDeliveryBackgroundColorTransparent"
                  (change)="onChangeDeliveryBarBackgroundTransparentColor($event)" />
                <label class="labelFont me-2 mb-0" for="background-transparent">
                  Transparent
                </label>
                <input *ngIf="!form.value.isDeliveryBackgroundColorTransparent"
                  formControlName="deliveryBarBackgroundColor" [value]="deliveryBarBackgroundColorList[0].value"
                  [style.background]="deliveryBarBackgroundColorList[0].value"
                  [(colorPicker)]="deliveryBarBackgroundColorList[0].value" [cpOutputFormat]="'hex'"
                  [cpPosition]="'left'" style="border: '1px solid #cfd0d7'; padding: 5px 10px; border-radius: 6px;"
                  (colorPickerChange)="onDeliveryBarBackgroundColorChange($event)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Address Bar Text Color -->
    <div class="row p-0 m-0 mb-4" *ngIf="modalConfig.data.sellerType === 'storeFrontSeller'">
      <div class="col-md-12 p-0">
        <div class="formInput">
          <label class="labelFont required mb-1">Select Address Bar Color</label>
          <div class="d-flex flex-column flex-wrap gap-2">
            <div class="d-flex align-items-center gap-2">
              <label class="labelFont m-0 checkbox-container">Address Type Color</label>
              <div class="toggle-checkbox">
                <input type="checkbox" class="me-1 ml-2" id="addres-text-transparent"
                  formControlName="isAddressBarTypeTransparent"
                  (change)="onChangeAddressBarTypeTransparentColor($event)" />
                <label class="labelFont me-2 mb-0" for="addres-text-transparent">
                  Transparent
                </label>
                <input *ngIf="!form.value.isAddressBarTypeTransparent" formControlName="addressBarTypeColor"
                  [value]="addressBarTypeColorList[0].value" [style.background]="addressBarTypeColorList[0].value"
                  [(colorPicker)]="addressBarTypeColorList[0].value" [cpOutputFormat]="'hex'" [cpPosition]="'left'"
                  style="border: '1px solid #cfd0d7'; padding: 5px 10px; border-radius: 6px;"
                  (colorPickerChange)="onAddressBarTypeColorChange($event)" />
              </div>
            </div>
            <div class="d-flex align-items-center gap-2">
              <label class="labelFont m-0 checkbox-container">Address Color</label>
              <div class="toggle-checkbox">
                <input type="checkbox" class="me-1 ml-2" id="addres-background-transparent"
                  formControlName="isAddressBarTextTransparent"
                  (change)="onChangeAddressBarTextTransparentColor($event)" />
                <label class="labelFont me-2 mb-0" for="addres-background-transparent">
                  Transparent
                </label>
                <input *ngIf="!form.value.isAddressBarTextTransparent" formControlName="addressBarTextColor"
                  [value]="addressBarTextColorList[0].value" [style.background]="addressBarTextColorList[0].value"
                  [(colorPicker)]="addressBarTextColorList[0].value" [cpOutputFormat]="'hex'" [cpPosition]="'left'"
                  style="border: '1px solid #cfd0d7'; padding: 5px 10px; border-radius: 6px;"
                  (colorPickerChange)="onAddressBarTextColorChange($event)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Profile Image Upload Input-->
    <div class="row p-0 m-0 mb-4" *ngIf="modalConfig.data.sellerType === 'storeFrontSeller'">
      <div class="col-md-12 p-0">
        <div class="formInput">
          <label class="labelFont">Upload profile image</label>
          <div class="upload-wrapper">
            <div class="banner-upload cursor-pointer p-2" appDropZone (onFileDrop)="onProfileImageDrop($event)"
              [ngClass]="{'submitted': !profileUploadImage}" (click)="profileFile.click()">
              <div class="hoverImage" *ngIf="profileUploadImage"></div>
              <input type="file" name="profilePic" #profileFile class="banner-file" formControlName="profile"
                accept="image/jpeg,image/png,image/jpg,image/gif" (change)="onProfileImageUpload($event)"
                id="banner-upload" />
              <div class="d-flex flex-column justify-content-center" *ngIf="!profileUploadImage">
                <img class="upload-icon" src="assets/icon-logo-upload-placeholder.svg">
                <p class="uploadTitle d-flex justify-content-center mt-1">Drag & Drop a PNG, JPG, JPEG or GIF File
                  here
                </p>
                <label class="button">
                  <p class="mb-0 py-2">Select From Computer</p>
                </label>
              </div>
              <img [src]="profileUploadImage?.url" alt="coverPic" class="uploaded-image"
                [hidden]="!profileUploadImage?.url">
            </div>
            <button class="delete-item-cta mb-3 me-3" *ngIf="profileUploadImage?.url"
              (click)="removeProfileUploadedFile($event)">
              <img src="/assets/icon-delete-item.svg" alt="icon-delete-banner">
            </button>
          </div>
          <div class="banner-size-info">Supported aspect ratio: 1:1 - Eg: (
            Width 500 , Height 500 )</div>
        </div>
      </div>
    </div>

    <div
      *ngIf="appAbility.can('create', appAbilityKey.CUSTOMER_ADMIN_THEME_BANNER_MANAGEMENT) || appAbility.can('update', appAbilityKey.CUSTOMER_ADMIN_THEME_BANNER_MANAGEMENT) || appAbility.can('update', appAbilityKey.BRAND_ADMIN_BANNER_MANAGEMENT) || appAbility.can('create', appAbilityKey.BRAND_ADMIN_BANNER_MANAGEMENT)"
      class="save-cta-section mt-4">
      <button class="save-cta" type="submit" [disabled]="formSubmitted" (click)="saveChange()">
        Save
      </button>
    </div>
  </form>
</div>