import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { RestApiService } from '../api.service';
import { HttpClientService } from '../http-client.service';
import { DELAY_NOTIFICATION_EVENT_TYPE } from 'src/enums/order.enum';

export enum NOTIFICATION_EVENT_TYPE {
  NOTIFICATION = 'notification',
  CALL = 'call',
  ALL = 'all',
  COUPON = 'coupon'
}

export enum CALL_RESPONSE_TYPE {
  ANSWERED = 'answered',
  NOT_ANSWERED = 'not-answered',
  NOT_REACHABLE = 'not-reachable'
}

export enum DELAY_NOTIFICATION_EVENT_CUSTOMER_TYPE {
  NOTIFICATION = 'notification',
  CALL = 'call',
  MISSED_CALL = 'missedCall',
  ALL = 'all',
  COUPON = 'coupon',
  WHATS_APP_NOTIFICATION = 'whatsAppNotification'
}

export interface ICreatePayloadPushNotification {
  userType: string;
  sendType: string;
  phoneNumberList?: Array<string> | null,
  title: string,
  body: string,
  photoS3FileName?: string,
  photoOriginalFileName?: string,
  data?: object,
}

export interface IDelayNotification {
  templateId?: string;
  templateCategory: string;
  templateName: string;
  title: string;
  content: string;
  createdAt: string;
  updatedAt: string;
  templateType?: string;
  body?: string;
}

export interface IDelayNotificationPayload {
  templateId?: string;
  templateCategory: string;
  templateName: string;
  title: string;
  content: string;
}

export interface IDelayNotificationSendNotificationPayload {
  templateId?: string,
  notificationMessage?: string,
  remarks?: string,
  callMadeToCustomer?: boolean,
  customerResponse?: CALL_RESPONSE_TYPE,
  eventType?: DELAY_NOTIFICATION_EVENT_CUSTOMER_TYPE;
  templateType?: string;
}

export interface ICouponPayload {
  couponId?: string,
  couponName?: string,
  eventType?: NOTIFICATION_EVENT_TYPE,
  callMadeToCustomer: boolean,
  remarks?: string,
}

export const TemplateCategories = [{
  label: 'Customer Delay',
  value: 'customerDelay',
},
];

export enum NotificationMedium {
  APP = 'app',
  WHATSAPP = 'whatsapp'
}

export interface IWhatsAppNotificationPayload {
  to: string;
  templateType: string;
}

@Injectable()
export class PushNotificationService extends RestApiService {
  protected override baseApiUrl = environment.config['ADMIN_SERVICE_URL'];

  private servicePath = `${this.baseApiUrl}/push-notifications`;

  private delayNotificationServicePath = `${this.baseApiUrl}/todays-order/send-delay-notification`;

  protected customerServiceBaseApiUrl = environment.config['CUSTOMER_SERVICE_URI'];

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  async create(payload: ICreatePayloadPushNotification) {
    const apiPath = this.servicePath;
    return this.httpPost(apiPath, payload);
  }

  async getDelayNotification(id: string): Promise<{data: IDelayNotification}> {
    const apiPath = `${this.baseApiUrl}/templates/${id}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getAllDelayNotifications(templateCategory: string):
    Promise<{ data: IDelayNotification[] }> {
    const apiPath = `${this.baseApiUrl}/templates/list/${templateCategory}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async createDelayNotification(payload: IDelayNotificationPayload) {
    const apiPath = `${this.baseApiUrl}/templates`;
    return this.httpPost(apiPath, payload);
  }

  async deleteDelayNotification(id: string) {
    const apiPath = `${this.baseApiUrl}/templates/${id}`;
    return this.httpDelete(apiPath);
  }

  async updateDelayNotification(id: string, payload: IDelayNotificationPayload) {
    const apiPath = `${this.baseApiUrl}/templates/${id}`;
    return this.httpPut(apiPath, payload);
  }

  async updateCouponResponse(referenceId: string, payload: ICouponPayload) {
    const apiPath = `${this.delayNotificationServicePath}/reference/${referenceId}`;
    return this.httpPost(apiPath, payload);
  }

  async getAllWhatsAppDelayNotifications(): Promise<{ data: IDelayNotification[]; }> {
    const apiPath = `${this.customerServiceBaseApiUrl}/common/today-orders/whatsapp-templates`;
    return this.httpGet(apiPath);
  }

  async updateCallResponse(referenceId: string, payload: IDelayNotificationSendNotificationPayload) {
    const apiPath = `${this.delayNotificationServicePath}/reference/${referenceId}`;
    return this.httpPost(apiPath, payload);
  }

  async sendWhatsappNotification(payload: IWhatsAppNotificationPayload) {
    const apiPath = `${this.customerServiceBaseApiUrl}/common/today-orders/whats-app/send-notification`;
    return this.httpPost(apiPath, payload);
  }
}
