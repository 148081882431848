export enum OrderStatusEnum {
  YET_TO_PREPARE = 'yet-to-prepare',
  PREPARING = 'preparing',
  READY_FOR_PICKUP = 'ready-for-pickup',
  RIDER_ASSIGNED = 'rider-assigned',
  PICKEDUP = 'pickedup',
  DELIVERED = 'delivered',
  UNDELIVERED = 'undelivered',
  CANCELLED = 'cancelled',
  PAYMENT_FAILED = 'payment-failed',
}
// export enum PaymentStatusEnum {
//   PAID = 'paid',
//   FAILED = 'failed',
//   REFUND = 'refund',
//   CREATED = 'created',
// }

export enum OrderCategoryEnum {
  FOOD_ITEMS = 'foodItems',
  SELLER_PRODUCTS = 'sellerProducts',
  STORE_FRONT_PRODUCTS = 'storeFrontProducts'
}

export enum OrderTabTypeEnum {
  REGULAR = 'regular',
  SUBSCRIPTION = 'subscription',
}

export enum BrandTabTypeEnum {
  STOREFRONT = 'storeFront',
  CUSTOMER = 'customer',
  RIDER = 'rider',
}

export enum PaymentStatusEnum {
  PAYMENT_CREATED = 'created',
  PAYMENT_INITIATED = 'initiated',
  PAYMENT_PROCESSING = 'processing', // Status not needed for now
  PAYMENT_CAPTURED = 'captured',
  REFUND_INITIATED = 'refundInitiated',
  REFUND_FAILED = 'refundFailed',
  REFUND_PROCESSED = 'refundProcessed',
  PARTIALLY_REFUNDED = 'partiallyRefunded', // Imaginary status to indicate partial refund
  PAYMENT_FAILED = 'failed',
  PAYMENT_COMPLETED = 'completed',
}
export enum PaymentTypeEnum {
  CASH = 'cash',
  ONLINE = 'online'
}

export enum PaymentTypeValueEnum {
  PURCHASE = 'purchase',
  REFUND = 'refund'
}

export enum OrderChannel {
  MOBILE = 'mobile',
  WEB = 'web',
  ADMIN_PANEL = 'adminPanel'
}

export enum OrderTypes {
  NOW = 'now',
  PREORDER = 'preOrder',
  PREBOOKING = 'preBooking',
  GET_IT_IMMEDIATELY = 'getItImmediately',
  SCHEDULED = 'scheduled',
  INSTANT = 'instant',
  SUBSCRIPTION = 'subscription',
  FESTIVALORDERS = 'festivalOrders',
  INSTANT_DELIVERY = 'instantDelivery'
}

export enum ProductTypesEnum {
  MADE_TO_ORDER = 'madeTo',
  READYMADE = 'readyMade',
}

export enum MealTimes {
  BREAKFAST = 'breakfast',
  LUNCH = 'lunch',
  SNACKS = 'snacks',
  DINNER = 'dinner',
}

export enum ChefOrderStatuses {
  ORDER_CONFIRMED = 'orderConfirmed',
  COOK_ACKNOWLEDGED = 'cookAcknowledged',
  ORDER_PREPARING = 'orderPreparing',
  READY_FOR_PICKUP = 'readyForPickUp',
  DE_ASSIGNING = 'deAssigning',
  DE_ASSIGNED = 'deAssigned',
  DE_REACHED = 'deReached',
  PARTIALLY_COMPLETE = 'partiallyFulfilled',
  ORDER_COMPLETED = 'orderCompleted',
  ORDER_INCOMPLETE = 'orderInComplete',
  NO_RIDER_AVAILABLE = 'noRiderAvailable',
  PACKED = 'packed',
  RETURN_TO_STORE = 'returnToStore',
}

export enum PurchaseOrderStatuses {
  ORDER_PLACED = 'orderPlaced',
  ORDER_CONFIRMED = 'orderConfirmed',
  CHEF_ORDER_CREATED = 'chefOrderCreated',
  ORDER_PREPARING = 'orderPreparing',
  DE_ASSIGNING = 'deAssigning',
  DE_ASSIGNED = 'deAssigned',
  ORDER_PICKED_UP = 'orderPickedUp',
  DE_REACHED = 'deReached',
  ORDER_DELIVERED = 'orderDelivered',
  ORDER_UNDELIVERED = 'orderUndelivered',
  ORDER_CANCELLED = 'orderCancelled',
  ORDER_FAILED = 'orderFailed',

  ORDER_PROCESSING = 'orderProcessing',
  PARTIALLY_SHIPPED = 'partiallyShipped',
  PARTIALLY_DELIVERED = 'partiallyDelivered'
}

export enum DELAY_NOTIFICATION_EVENT_TYPE {
  NOTIFICATION = 'notification',
  CALL = 'call',
  MISSED_CALL = 'missedCall',
  ALL = 'all',
  COUPON = 'coupon'
}
