import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { RestApiService, ServiceType } from '../api.service';
import { HttpClientService } from '../http-client.service';
import { IS3FileResponse } from '../upload.service';

interface Location {
  type: 'Point';
  coordinates: [number, number];
}

interface IEntity {
  entityId: string;
  entityType: string;
  entityName: string;
}
interface Address {
  line1Address: string;
  line2Address: string;
  line3Address: string;
  state: string;
  nearestLocation: string;
  pincode: string;
  city?: string;
  location?: Location;
}

export interface getSubZoneDetail {
  parentZoneId: string;
  parentZoneName: string;
  subZoneId: string;
  radiusInKms: number;
  subZoneName: string;
  subZoneAliasName: string;
  location: Location;
  active: boolean;
}

export interface IKitchenAssets {
  contentType: string;
  fileType: string;
  originalFileName: string;
  s3FileName: string;
  fileUrl: IS3FileResponse;
  thumbnailS3FileName?: string;
  thumbnailOriginalFileName?: string;
  thumbnailImageUrl: IS3FileResponse;
}

export interface ICharges {
  deliveryCharge: number;
  handlingCharge: number;
  smallOrderCharge: number;
}

export interface IKitchen {
  cookId: string;
  kitchenId?: string;
  logoImageS3FileName?: string;
  isKitchenInspected?: boolean;
  inspectionInfo?: string;
  logoImageOriginalFileName?: string;
  logoImageUrl?: IS3FileResponse;
  coverImageS3FileName?: string;
  coverImageOriginalFileName?: string;
  coverImageUrl?: IS3FileResponse;
  kitchenName?: string;
  kitchenDescription?: string;
  address: Address;
  activeMeals?: {
    breakfast: boolean;
    lunch: boolean;
    snacks: boolean;
    dinner: boolean;
  };
  foodCuisineStyle?: string[];
  foodType?: string[];
  isHalal?: boolean,
  kitchenAvailability?: any; // string[] to Object of Objects
  isActive?: boolean;
  subZoneId?: string;
  parentZoneId?: string;
  isForceDisabled?: boolean;
  statusUpdatedBy?: IEntity;
  statusUpdatedOn?: Date;
  tags?: string;
  assets?: IKitchenAssets[];
  product?: {
    kitchenName: string,
    logoImageOriginalFileName: string;
    logoImageS3FileName: string;
    logoImageUrl?: IS3FileResponse;
    assets: IKitchenAssets[];
    revenueSharingPercentage?: number;
    satelliteStoreId?: string;
    isActive: boolean;
  };
  kitchenRatings?: {
    avgRating: number;
    totalReviewers: number;
  };
  KitchenBusinessAttributes?: {
    isAcceptSubscription: boolean;
    isAcceptB2B: boolean;
    dedicatedCRLUserId: string;
    // minimumCapabilities: number;
    maximumCapabilities: number;
    kitchenTierId: string;
    adminUserName?: string;
    tierName?: string;
  };
  showScheduledItems?: boolean;
  charges?: ICharges;
}

export interface InspectedBy {
  entityId: string;
  entityName?: string;
  entityType: string;
}

export enum InspectionAssetFileTypes {
  KITCHEN_OR_MFG_PLACE_IMAGE = 'kitchenOrMfgPlaceImage',
  FOOD_SAMPLE1_IMAGE = 'foodSample1Image',
  FOOD_SAMPLE2_IMAGE = 'foodSample2Image',
  HEDONICS_SCALE_RANKING_FILE = 'hedonicsScaleRankingFile',
  KITCHEN_AUDIT_CHECK_LIST_FILE = 'kitchenAuditChecklistFile'
}
export interface IFile {
  url: string;
  expiresIn: number;
}
export interface IInspectionAssets {
  s3FileName: string;
  originalFileName: string;
  fileType: InspectionAssetFileTypes;
  fileDesc?: string;
  fileUrl: IFile;
}

export interface IKitchenInspectionLog {
  kitchenId: string;
  inspectedBy: InspectedBy;
  inspectedDate: Date;
  remarks?: string;
  inspectionImages: string[];
  inspectionAssets: IInspectionAssets[];
  __v: number;
}

export interface IKitchenInspectionLogPayload {
  kitchenId: string;
  inspectedBy: InspectedBy | null;
  inspectedDate: Date;
  remarks?: string;
  inspectionImages: string[];
}

export interface IKitchenTiers {
  _id: string;
  kitchenTierId: string;
  tierName: string;
  createdBy: IEntity;
  createdAt: string;
  updatedAt: string;
  __v: number;
  updatedBy: IEntity;
}

export interface IKitchenTierResponse {
  totalKitchenTiers: number;
  totalPages: number;
  kitchenTiers: Array<IKitchenTiers>;
}

export interface IUpdateScheduleItemPayload {
  showScheduledItems: boolean;
}

@Injectable()
export class KitchenApiService extends RestApiService {
  // TODO: update this end point
  protected override baseApiUrl = environment.config['KITCHEN_SERVICE_URL'];

  protected adminServiceApiUrl = environment.config['ADMIN_SERVICE_URL'];

  // TODO: update this end point
  private servicePath = `${this.baseApiUrl}/kitchens`;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  async get(id: string) {
    const apiPath = `${this.servicePath}/${id}`;
    return this.httpGet(apiPath);
  }

  async getByCookId(cookId: string): Promise<{ data: IKitchen; }> {
    const apiPath = `${this.servicePath}/cooks/${cookId}`;
    return this.httpGet(apiPath);
  }

  async getAll() {
    const apiPath = this.servicePath;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async create(payload: IKitchen) {
    const apiPath = `${this.servicePath}/`;
    return this.httpPost(apiPath, payload);
  }

  async delete(id: string) {
    const apiPath = `${this.servicePath}/${id}`;
    return this.httpDelete(apiPath);
  }

  async updateOrCreate(payload: Partial<IKitchen>) {
    const apiPath = `${this.servicePath}/`;
    return this.httpPut(apiPath, payload);
  }

  async update(id: string, payload: Partial<IKitchen>) {
    const apiPath = `${this.servicePath}/kitchen-status/${id}`;
    return this.httpPatch(apiPath, payload);
  }

  async updateScheduleItemStatus(id: string, payload: IUpdateScheduleItemPayload) {
    const apiPath = `${this.servicePath}/${id}/schedule-visibility/status`;
    return this.httpPatch(apiPath, payload);
  }

  async updateKitchen(id: string, payload: Partial<IKitchen>) {
    const apiPath = `${this.servicePath}/${id}`;
    return this.httpPut(apiPath, payload);
  }

  async updateSubzoneStatus(id: string, payload: { isActive: boolean, isForceDisabled?: boolean; }) {
    const apiPath = `${this.servicePath}/zonal-kitchen-status/${id}`;
    return this.httpPatch(apiPath, payload);
  }

  async fetchKitchenInspectionByKitchenId(
    kitchenId: string,
    queryParams?: Record<any, (string | number)>,
  ): Promise<{
    data: {
      inspectionLogs: IKitchenInspectionLog[],
      totalPages: number,
      totalInspectionLogsCount: number;
    };
  }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if (queryParams['skip'] !== undefined) {
        searchParams.set('skip', String(queryParams['skip']));
      }
    }
    const apiPath = `${this.servicePath}/${kitchenId}/inspection-logs?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async createKitchenInspectionLog(payload: IKitchenInspectionLogPayload) {
    const apiPath = `${this.servicePath}/inspection-log`;
    return this.httpPost(apiPath, payload);
  }

  async getKitchenDetails(kitchenId: string) {
    const apiPath = `${this.adminServiceApiUrl}/kitchens/details/${kitchenId}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getKitchenTierName(): Promise<{ data: IKitchenTierResponse; }> {
    const apiPath = `${this.baseApiUrl}/common/kitchen-tiers`;
    return this.httpGet(apiPath);
  }
}
