import { Injectable } from '@angular/core';
import { IS3FileResponse } from '../upload.service';
import { environment } from '../../environments/environment';
import { RestApiService, envName } from '../api.service';
import { HttpClientService } from '../http-client.service';

export enum BannerStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface BannerConfig {
  zone: string;
  bannerTitle: string;
  fromDate: Date;
  toDate: Date;
  description?: string;
  active: boolean;
  bannerS3FileName: string;
  bannerOriginalFileName: string;
  subZoneId: string;
  parentZoneId: string;
  displayOrder?: number;
  isPureVegCompatible?: boolean;
}

export interface ICookTimeSlotConfig {
  breakfastStartTime: string;
  lunchStartTime: string;
  snacksStartTime: string;
  dinnerStartTime: string;
}

export interface ISectionCardQuery {
  nearBy?: number;
  new?: boolean;
  cuisine?: string[];
  isGetItImmediatelySection?: boolean;
}
export interface ISectionCard {
  name: string;
  title: string;
  description: string;
  parentZoneId: string;
  subZoneId: string;
  isActive: boolean;
  type: string;
  query: ISectionCardQuery;
  templateId: string;
  createdAt: Date;
  isPureVegCompatible?: boolean;
  requiredLimit?: number;
  cardBgImage: string;
  cardBgImageLg?: string; // mandatory only for get it immediately
  cardBgImageURL?: string;
  cardBgImageLgURL?: string;
  preferredMealTime?: Array<string>;
}

export interface ISectionCardPayload {
  name: string;
  title: string;
  description: string;
  parentZoneId: string;
  subZoneId: string;
  isActive: boolean;
  type: string;
  query: ISectionCardQuery;
  requiredLimit?: number;
  isPureVegCompatible?: boolean;
  cardBgImage: string;
  cardBgImageLg?: string;
  preferredMealTime?: Array<string>;
}
export interface IReferralAppConfigs {
  socketUrl?: string;
  referrerCouponAmount?: number,
  refereeCouponAmount?: number,
  referralUrl?: IS3FileResponse;
  appReferText?: string;
  referralImage?: string;
  linkReferText?: string;
}

export interface IMapAPIConfig {
  directions: string;
  distanceMatrix: string;
  forwardGeoCoding: string;
  reverseGeoCoding: string;
}

export interface MapAPIConfig {
  mapAPIConfig: IMapAPIConfig;
}

export interface IDeliveryChargeSuggestionConfig {
  isActive: boolean;
  maxAmt: number;
}

export interface DeliveryChargeSuggestionConfig {
  data: {
    default: IDeliveryChargeSuggestionConfig;
  };
}

export interface OrdersDelayTimeConfig {
  orderStatus: string;
  normalOrderTimeDiff: number;
  middleOrderTimeDiff: number;
}
export interface IOrdersDelayTimeConfig {
  ordersDelayTime: OrdersDelayTimeConfig[];
}

export interface IOrdersDelayResponsePaylod {
  liveOrdersDelayTime: IOrdersDelayTimeConfig;
}

interface ICuisineStyle {
  sectionText: string;
  itemCardLabel: string;
  sectionTextDescription: string;
  legendIconName: string;
  notApplicableText: string;
}
interface IOtherUrls {
  privacyPolicy?: string;
  termsAndCondition?: string;
  refundPolicy?: string;
  legalAndFaqs?: string;
  comingSoonUrl?: string;
}
interface IPhoneNumber {
  whatsAppBusiness?: string;
  customerCare?: string;
}
interface ICustomerCareTiming {
  from?: string;
  to?: string;
  customerCareGenericMessage?: string;
}

export interface IOrderNowConfigurationPayload {
  deliveryTimeSlotMessage: string;
  deliveryTimeSlotOption: string;
  displayDeliveryTimeSlot: boolean;
  isActive: boolean;
  parentZoneId?: string;
  parentZoneName?: string;
}

export interface IOrderNowConfiguration {
  default: IOrderNowConfigurationPayload;
}

export interface ICustomerOrderNowConfig {
  configuration: IOrderNowConfigurationPayload;
  parentZoneId?: string;
}

export interface IConfigResponse {
  refereeCouponAmount: number;
  razorPayId: string;
  appReferText: string;
  linkReferText: string,
  referrerCouponAmount: number;
  referralImage: string;
  cuisineStyle: Array<ICuisineStyle>,
  socialShareCaption: string;
  otherUrls: IOtherUrls,
  phoneNumber: IPhoneNumber,
  customerCareTiming: ICustomerCareTiming,
  interStateParentZones: Array<string>;
  mapAPIConfig?: string;
  orderNowConfiguration: IOrderNowConfiguration;
  searchVendor?: string;
}

export interface IAppManageAccountConfigs {
  phoneNumber?: IPhoneNumber;
  otherUrls?: IOtherUrls;
  maximumOtpRestrictionCount?: number;
  customerCareTiming?: ICustomerCareTiming;
}

interface IUpdateOtherStateZones {
  interStateParentZones: Array<string>;
}

export interface IGetItImmediatelyConfig {
  name: string;
  description: string;
  notApplicableMessage: string;
  deliveryMessage: string;
  active: boolean;
}

export interface IRushInKitchenConfig {
  numberOfOrdersToIndicateRush: number;
  rushIndicatorMessage: string;
  active: boolean;
}

export interface IGreetingMessageAssets {
  morningGreetingImageS3FileName?: string | null;
  morningGreetingImageOriginalFileName?: string;
  afternoonGreetingImageS3FileName?: string | null;
  afternoonGreetingImageOriginalFileName?: string;
  eveningGreetingImageS3FileName?: string | null;
  eveningGreetingImageOriginalFileName?: string;
  nightGreetingImageS3FileName?: string | null;
  nightGreetingImageOriginalFileName?: string;
  customGreetingImageS3FileName?: string | null;
  customGreetingImageOriginalFileName?: string;
}
export interface ICustomerAppCustomGreeting {
  defaultGreetingText: string;
  customText: string;
  isCustomTextVisible: boolean;
  messageAssets: IGreetingMessageAssets;
}
export interface ICustomerAppCustomGreetingConfig {
  customerAppCustomGreeting: ICustomerAppCustomGreeting;
}

export interface ISearchVendorConfig {
  searchVendor: string;
}

export interface IMinimumOrderValueConfig {
  minimumOrderValueOfSellerProducts: string;
}

export interface ISubscriptionDeliveryTimeSlotPayload {
  filters: {
    parentZoneId: string;
    mealTime: string;
  };
}

export interface ISubscriptionDeliveryTimeSlots {
  _id: string;
  timeSlotEndTime: string;
  formattedTimeSlotEndTime?: string;
  parentZoneId: string;
  timeSlotStartTime: string;
  formattedTimeSlotStartTime?: string;
  mealTime: string;
  timeSlotId: string;
  slot: number;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface IStoreFrontSectionCardPayload {
  sectionTemplateId?: string;
  parentZoneId: string;
  sectionTemplateType: string;
  sectionContentType: string;
  sequence?: number;
  isActive: boolean;
  templateVariables: {
    sectionLabel: string;
    sectionLabelColor?: string;
    sectionDescription: string;
    sectionDescriptionColor?: string;
    headerImage?: {
      s3FileName: string;
      originalFileName: string;
    },
    backgroundImage?: {
      s3FileName: string;
      originalFileName: string;
    },
    sectionBanner?: {
      s3FileName: string;
      originalFileName: string;
    },
    rowsCount: number;
    minStockQuantity?: number;
    minItemsPerRow: number;
    maxItemsPerRow: number;
  },
  templateData: {
    sectionProducts?: Array<string>;
    sectionBrands?: Array<string>;
    sectionPlatformCategories?: Array<string>;
    sectionPlatformSubCategories?: Array<string>;
  },
  validity: {
    startDate: string;
    endDate: string;
  };
}

export interface IStoreFrontSectionCard {
  _id: string;
  sectionTemplateId: string;
  parentZoneId: string;
  sectionTemplateType: string;
  sectionContentType: string;
  templateVariables: {
    sectionLabel: string;
    sectionLabelColor: string;
    sectionDescription: string;
    sectionDescriptionColor: string;
    headerImage: {
      s3FileName: string;
      originalFileName: string;
    },
    headerImageUrl: string;
    backgroundImage: {
      s3FileName: string;
      originalFileName: string;
    },
    backgroundImageUrl: string;
    sectionBanner: {
      s3FileName: string;
      originalFileName: string;
    },
    sectionBannerImageUrl: string;
    rowsCount: number;
    minStockQuantity?: number;
    minItemsPerRow: number;
    maxItemsPerRow: number;
  },
  templateData: {
    sectionProducts?: Array<string>;
    sectionBrands?: Array<string>;
    sectionPlatformCategories?: Array<string>;
    sectionPlatformSubCategories?: Array<string>;
  },
  validity: {
    startDate: string;
    endDate: string;
  },
  sequence: number;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  __v: 0;
}

export interface IAllStoreFrontSectionCardResponse {
  results: Array<IStoreFrontSectionCard>;
  totalCount: number;
  totalPages: number;
}

@Injectable()
export class ConfigApiService extends RestApiService {
  // TODO: update this end point
  protected override baseApiUrl = environment.config['CONFIG_SERVICE_URL'];

  protected adminApiUrl = environment.config['ADMIN_SERVICE_URL'];

  private servicePath = `${this.baseApiUrl}/common/configurations/`;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  async getConfigSettings(context: string) {
    const apiPath = `${this.baseApiUrl}/configurations/generic-config-settings/${context}`;
    return this.httpGet(apiPath);
  }

  async getDeliveryChargeSuggestionConfigSettings() {
    const apiPath = `${this.adminApiUrl}/delivery-charge-suggestion/context/app-configs-customer-${envName}`;
    return this.httpGet(apiPath);
  }

  async getSubZoneInactiveText() {
    const apiPath = `${this.servicePath}context/app-configs-customer-${envName}/attribute/inactiveSubZonesTemplateText`;
    return this.httpGet(apiPath);
  }

  async getAndroidConfigSettings(appName: string): Promise<{
    data: {
      '_id': string;
      'attribName': string;
      'context': string;
      'cookrId': string;
      'cookrConfigId': string;
      'value': string;
    }[];
  }> {
    const apiPath = `${this.baseApiUrl}/configurations/android-config-settings/${appName}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async fetchAndroidConfigSettings(queryParams?: Record<string, any>): Promise<{
    data: {
      '_id': string;
      'attribName': string;
      'context': string;
      'cookrId': string;
      'cookrConfigId': string;
      'value': string;
    }[];
  }> {
    const searchParams = new URLSearchParams({
    });
    if (queryParams?.['versionCode']) {
      searchParams.set('versionCode', queryParams['versionCode']);
    }
    const apiPath = `${this.adminApiUrl}/app-version-config/android?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async updateAndroidConfig(appName: string, payload: BannerConfig) {
    const apiPath = `${this.servicePath}android-playstore-configs/${appName}`;
    return this.httpPut(apiPath, payload);
  }

  async updateCustomerAppAndroidConfig(payload: BannerConfig) {
    const apiPath = `${this.adminApiUrl}/app-version-config/android`;
    return this.httpPatch(apiPath, payload);
  }

  async getVersionCodes(operatingSyatem: string): Promise<{
    data: any;
  }> {
    const apiPath = `${this.adminApiUrl}/app-version-config/${operatingSyatem}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getIOSConfigSettings(queryParams?: Record<string, any>): Promise<{
    data: {
      '_id': string;
      'attribName': string;
      'context': string;
      'cookrId': string;
      'cookrConfigId': string;
      'value': string;
    }[];
  }> {
    const searchParams = new URLSearchParams({
    });
    if (queryParams?.['versionCode']) {
      searchParams.set('versionCode', queryParams['versionCode']);
    }
    const apiPath = `${this.adminApiUrl}/app-version-config/ios?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async updateIOSConfig(payload: BannerConfig) {
    const apiPath = `${this.adminApiUrl}/app-version-config/ios`;
    return this.httpPatch(apiPath, payload);
  }

  async getAllSectionCards(queryParams?: Record<string, any>): Promise<{ data: ISectionCard[]; }> {
    const searchParams = new URLSearchParams({
    });
    if (queryParams?.['parentZoneId']) {
      searchParams.set('parentZoneId', queryParams['parentZoneId']);
    }
    if (queryParams?.['type']) {
      searchParams.set('type', queryParams['type']);
    }
    if (queryParams?.['subZoneId']) {
      searchParams.set('subZoneId', queryParams['subZoneId']);
    }
    const apiPath = `${this.adminApiUrl}/section-card-templates?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getSectionCardSequence(queryParams?: Record<string, any>)
    : Promise<{ data: ISectionCard[]; }> {
    const searchParams = new URLSearchParams({
    });
    if (queryParams?.['parentZoneId']) {
      searchParams.set('parentZoneId', queryParams['parentZoneId']);
    }
    if (queryParams?.['subZoneId']) {
      searchParams.set('subZoneId', queryParams['subZoneId']);
    }
    const apiPath = `${this.adminApiUrl}/section-card-templates/sequence/list?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getProductSectionCardSequence(queryParams?: Record<string, any>)
    : Promise<{ data: ISectionCard[]; }> {
    const searchParams = new URLSearchParams({
    });
    if (queryParams?.['parentZoneId']) {
      searchParams.set('parentZoneId', queryParams['parentZoneId']);
    }
    if (queryParams?.['templateType']) {
      searchParams.set('templateType', queryParams['templateType']);
    }
    const apiPath = `${this.adminApiUrl}/section-card-templates/sequence/list?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async updateSectionCardSequence(
    subZoneId: string,
    payload: { templateIds: string[]; },
  ): Promise<ISectionCard[]> {
    const apiPath = `${this.adminApiUrl}/section-card-templates/modify-sequence/${subZoneId}`;
    return this.httpPut(apiPath, payload);
  }

  async updateProductSectionCardSequence(
    parentZoneId: string,
    payload: { templateIds: string[]; },
  ): Promise<ISectionCard[]> {
    const apiPath = `${this.adminApiUrl}/section-card-templates/product/modify-sequence?parentZoneId=${parentZoneId}`;
    return this.httpPut(apiPath, payload);
  }

  async createSectionCardConfig(payload: any, query?: string) {
    const searchParams = new URLSearchParams({
    });
    if (query) {
      searchParams.set('addTo', query);
    }
    const apipath = `${this.adminApiUrl}/section-card-templates?${searchParams.toString()}`;
    return this.httpPost(apipath, payload);
  }

  async updateSectionCardConfig(templateID: string, payload: Partial<any>) {
    const apipath = `${this.adminApiUrl}/section-card-templates/${templateID}`;
    return this.httpPut(apipath, payload);
  }

  async updateActiveSectionCardConfig(
    templateId: String,
    queryParams: Partial<ISectionCardPayload>,
  ): Promise<ISectionCard> {
    const searchParams = new URLSearchParams();
    if (queryParams['isActive']) {
      searchParams.set('active', String(queryParams['isActive']));
    }
    const apiPath = `${this.adminApiUrl}/section-card-templates/${templateId}?${searchParams.toString()}`;
    return this.httpPatch(apiPath);
  }

  async deleteSectionCardConfig(templateId: string) {
    const apiPath = `${this.adminApiUrl}/section-card-templates/${templateId}`;
    return this.httpDelete(apiPath);
  }

  async updateBannerConfig(payload: BannerConfig) {
    const apiPath = `${this.servicePath}banner-configs`;
    return this.httpPut(apiPath, payload);
  }

  async deleteBannerConfig(context: string, bannerTitle: string) {
    const apiPath = `${this.servicePath}banner-configs`;
    return this.httpDelete(apiPath, {
      context,
      bannerTitle,
    });
  }

  async updateTimeSlotConfig(payload: ICookTimeSlotConfig) {
    const apiPath = `${this.servicePath}cook-time-slot-configs`;
    return this.httpPut(apiPath, payload);
  }

  async updateReferralAppConfigs(appName: string, payload: IReferralAppConfigs) {
    const apiPath = `${this.servicePath}app-configs/${appName}/${envName}`;
    return this.httpPut(apiPath, payload);
  }

  async updateStoreFrontReferralAppConfigs(appName: string, payload: IReferralAppConfigs) {
    const apiPath = `${this.servicePath}config-nourisho/${appName}/${envName}`;
    return this.httpPut(apiPath, payload);
  }

  async updateMapConfig(appName: string, payload: IMapAPIConfig) {
    const apiPath = `${this.servicePath}map-configs/${appName}/${envName}-v2`;
    return this.httpPut(apiPath, { mapAPIConfig: payload });
  }

  async updateDeliveryChargeSuggestionConfig(payload: IDeliveryChargeSuggestionConfig) {
    const apiPath = `${this.adminApiUrl}/delivery-charge-suggestion/delivery-charge-suggestion-configuration/${envName}`;
    return this.httpPatch(apiPath, { configuration: payload });
  }

  async getOrdersDelayTime(context: string): Promise<{ data: IOrdersDelayResponsePaylod; }> {
    const apiPath = `${this.adminApiUrl}/configurations/find-config-by-context/${context}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async updateOrdersDelayTimeConfig(payload: IOrdersDelayTimeConfig) {
    const apiPath = `${this.adminApiUrl}/configurations/live-orders-delay-time-config`;
    return this.httpPut(apiPath, payload);
  }

  async updateAppManageAccountConfigs(appName: string, payload: IAppManageAccountConfigs) {
    const apiPath = `${this.servicePath}app-configs/${appName}/${envName}`;
    return this.httpPut(apiPath, payload);
  }

  async updateOtherStateZones(appName: string, payload: IUpdateOtherStateZones) {
    const apiPath = `${this.servicePath}app-configs/${appName}/${envName}`;
    return this.httpPut(apiPath, payload);
  }

  async updateGetItImmediatelyConfig(appName: string, payload: IGetItImmediatelyConfig) {
    const apiPath = `${this.servicePath}app-configs/${appName}/${envName}`;
    return this.httpPut(apiPath, { getItImmediately: payload });
  }

  async updateRushInKitchenConfig(appName: string, payload: IRushInKitchenConfig) {
    const apiPath = `${this.servicePath}app-configs/${appName}/${envName}`;
    return this.httpPut(apiPath, { rushInKitchen: payload });
  }

  async getCustomerAppCustomGreeting(context: string)
    : Promise<{ data: ICustomerAppCustomGreetingConfig; }> {
    const apiPath = `${this.adminApiUrl}/configurations/find-config-by-context/${context}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async updateCustomGreeting(payload: ICustomerAppCustomGreeting) {
    const apiPath = `${this.adminApiUrl}/configurations/customer-app/custom-greetings`;
    return this.httpPut(apiPath, payload);
  }

  async updateOrderNowConfig(appName: string, payload: ICustomerOrderNowConfig) {
    const apiPath = `${this.adminApiUrl}/configurations/order-now-configuration/${appName}/${envName}`;
    return this.httpPatch(apiPath, payload);
  }

  async updateSearchVendorConfig(payload: ISearchVendorConfig) {
    const apiPath = `${this.servicePath}search-vendor/context/app-configs-customer-${envName}`;
    return this.httpPatch(apiPath, payload);
  }

  async updateMinimumOrderValueConfig(payload: IMinimumOrderValueConfig) {
    const apiPath = `${this.adminApiUrl}/configurations/minimum-order-value/seller-products/customer/${envName}`;
    return this.httpPatch(apiPath, payload);
  }

  async fetchSubscriptionDeliveryTimeSlot(payload: ISubscriptionDeliveryTimeSlotPayload): Promise<{ data: Array<ISubscriptionDeliveryTimeSlots>; }> {
    const apiPath = `${this.baseApiUrl}/common/time-slots/fetch/subscriptions`;
    return this.httpPost(apiPath, payload);
  }

  // Storefront section related API's
  async createStorefrontSectionCardConfig(payload: IStoreFrontSectionCardPayload) {
    const apiPath = `${this.adminApiUrl}/nourisho/section-templates`;
    return this.httpPost(apiPath, payload);
  }

  async updateStorefrontSectionCardConfig(sectionTemplateId: string, payload: IStoreFrontSectionCardPayload) {
    const apiPath = `${this.adminApiUrl}/nourisho/section-templates/${sectionTemplateId}`;
    return this.httpPut(apiPath, payload);
  }

  async deleteStorefrontSectionCardConfig(sectionTemplateId: string) {
    const apiPath = `${this.adminApiUrl}/nourisho/section-templates/${sectionTemplateId}`;
    return this.httpDelete(apiPath);
  }

  async getAllStorefrontSectionCardConfig(queryParams: Partial<any>): Promise<{ data: IAllStoreFrontSectionCardResponse; }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if ('skip' in queryParams) {
        searchParams.set('skip', String(queryParams['skip'] ?? 0));
      }
      if (queryParams['parentZoneId']) {
        searchParams.set('parentZoneId', queryParams['parentZoneId']);
      }
      if (queryParams['sectionTemplateType']) {
        searchParams.set('sectionTemplateType', String(queryParams['sectionTemplateType']));
      }
      if ('search' in queryParams) {
        searchParams.set('searchTerm', String(queryParams['search']));
      } else {
        searchParams.delete('searchTerm');
      }
    }
    const apiPath = `${this.adminApiUrl}/nourisho/section-templates?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  async updateStorefrontSectionCardSequence(parentZoneId: string, payload: { sectionTemplateIds: string[]; },): Promise<IStoreFrontSectionCard[]> {
    const apiPath = `${this.adminApiUrl}/nourisho/section-templates/modify/sequence/${parentZoneId}`;
    return this.httpPut(apiPath, payload);
  }
}
