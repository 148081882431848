import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HotToastModule } from '@ngneat/hot-toast';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MsalInterceptor, MsalService, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalModule,
} from '@azure/msal-angular';
import { AbilityModule } from '@casl/angular';
import { PureAbility } from '@casl/ability';
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { PreBookingApiService } from '../services/api/pre-booking-api.service';
import { LogisticOrdersService } from '../services/api/logistic-orders.service';
import { PurchaseOrderApiService } from '../services/api/purchase-order-api.service';
import { ModalRef } from '../lib/modal/modal-ref';
import { AppAbility } from '../services/appAbility';
import { ModalConfig } from '../lib/modal/modal-config';
import { HttpClientInterceptor } from '../interceptors/http.interceptor';
import { AuthGuard } from '../guards/auth.guard';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { SidebarModule } from './shared/sidebar/sidebar.module';
import { S3Service } from '../services/upload.service';
import { MSALInstanceFactory, AzureAdConfigService, MSALInterceptorConfigFactory } from '../services/azure-ad-config.service';
import { AbilityGuard } from '../guards/ability.guard';
import { UserApiService } from '../services/api/user-api.service';
import { StorageService } from '../services/storage.service';
import { OrderDisputeApiService } from '../services/api/order-dispute.api.service';
import { OrderApiService } from '../services/api/order-api.service';
import { ManipulateThemeBannerModule } from './shared/pop-ups/manipulate-theme-banner/manipulate-theme-banner.module';
import { BroadcastMessageService } from '../services/api/broadcast-message.service';
import { ManipulateDeliveryPreferenceModule } from './shared/pop-ups/manipulate-delivery-preference-modal/manipulate-delivery-preference-modal.module';
import { CuisineApiService } from '../services/api/cuisine-api.service';
import { TagMasterApiService } from '../services/api/tag-masters-api.service';
import { VideoPreviewModalComponent } from './shared/pop-ups/video-preview-modal/video-preview-modal.component';
import { ConfirmationModalService } from './shared/pop-ups/confirmation-modal/confirmation-modal.service';
import { VideoPreviewComponent } from './shared/pop-ups/video-preview/video-preview.component';
import { PushNotificationService } from '../services/api/push-notification.service';
import { ProductApprovalApiService } from '../services/api/product-approval-api.service';
import { CategoryService } from '../services/api/category.service';
import { SatelliteStoreConfigService } from '../services/api/satellite-store-config.service';
import { CancellationReasonApiService } from '../services/api/cancellation-reason-api.service';
import { ProductItemApiService } from '../services/api/products.service';
import { SubscriptionService } from '../services/api/subscription.service';
import { OrderAcivityLogsComponent } from './shared/pop-ups/order-acivity-logs/order-acivity-logs.component';
import { ReviewsTrackingService } from '../services/api/reviews-tracking.service';
import { PartyOrderService } from '../services/api/party-order.service';
import { BrandProfileService } from '../services/api/brand-profile.service';
import { BrandOnboardApiService } from '../services/api/brand-onboard.service';

export function initAuth(azureAdConfigService: AzureAdConfigService) {
  return () => azureAdConfigService.loadAppConfigurations();
}

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    NotFoundPageComponent,
    VideoPreviewModalComponent,
    VideoPreviewComponent,
    OrderAcivityLogsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    SidebarModule,
    HotToastModule.forRoot({
      position: 'top-center',
      className: 'hot-toast',
      duration: 1500,
    }),
    LoadingBarModule,
    BrowserAnimationsModule,
    MsalModule,
    AbilityModule,
    ManipulateThemeBannerModule,
    ManipulateDeliveryPreferenceModule,
    NgbRatingModule,
    MatButtonModule,
    MatDialogModule,
  ],
  providers: [
    AuthGuard,
    ModalRef,
    OrderDisputeApiService,
    OrderApiService,
    AbilityGuard,
    ModalConfig,
    ModalRef,
    OrderApiService,
    PurchaseOrderApiService,
    LogisticOrdersService,
    BroadcastMessageService,
    PreBookingApiService,
    ProductApprovalApiService,
    ProductItemApiService,
    S3Service,
    MsalService,
    UserApiService,
    StorageService,
    AzureAdConfigService,
    CuisineApiService,
    TagMasterApiService,
    ConfirmationModalService,
    PushNotificationService,
    CategoryService,
    SatelliteStoreConfigService,
    CancellationReasonApiService,
    SubscriptionService,
    ReviewsTrackingService,
    PartyOrderService,
    BrandProfileService,
    BrandOnboardApiService,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initAuth,
      deps: [AzureAdConfigService],
      multi: true,
    },
    { provide: AppAbility, useValue: AppAbility() },
    { provide: PureAbility, useExisting: AppAbility },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
