import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
// import {
//   MAT_COLOR_FORMATS,
//   NGX_MAT_COLOR_FORMATS,
//   NgxMatColorPickerModule,
// } from '@angular-material-components/color-picker';
import { MatCardModule } from '@angular/material/card';
import { SpecialCategoryApiService } from '../../../../services/api/special-category-api.service';
import { DropZoneModule } from '../../../../directives/drop-zone/drop-zone.module';
import { ManipulateThemeBannerComponent } from './manipulate-theme-banner.component';
import { BannerApiService } from '../../../../services/api/banner.service';
import { ZoneApiService } from '../../../../services/api/zone-api.service';
import { CouponApiService } from '../../../../services/api/coupon-api.service';
import { KitchenApiService } from '../../../../services/api/kitchen-api.service';
import { ColorPickerModule } from 'ngx-color-picker';

@NgModule({
  declarations: [
    ManipulateThemeBannerComponent,
  ],
  providers: [
    BannerApiService,
    SpecialCategoryApiService,
    ZoneApiService,
    CouponApiService,
    KitchenApiService,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    DropZoneModule,
    MatInputModule,
    DragDropModule,
    NgSelectModule,
    MatCardModule,
    ColorPickerModule,
  ],
})
export class ManipulateThemeBannerModule { }
