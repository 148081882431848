/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { envName, RestApiService } from '../api.service';
import { HttpClientService } from '../http-client.service';
import { ITagMasters } from '../../constants/tag-master.constants';

export enum pageStyle {
  ITEM = 'item',
  KITCHEN = 'kitchen',
  KITCHEN_AND_ITEM = 'kitchenAndItem',
  PRODUCTS = 'products'
}

export enum statusBarBgColorConfig {
  TRANSPARENT = 'transparent',
  LIGHT_CONTENT = '#FFFFFF',
  OTHERS = 'others',
}

export enum statusBarTextColorConfig {
  LIGHT_CONTENT = 'light-content',
  DARK_CONTENT = 'dark-content',
}

export enum addressBarTextColorConfig {
  TRANSPARENT = 'transparent',
  LIGHT_CONTENT = 'light-content',
  DARK_CONTENT = 'dark-content',
  OTHERS = 'others',
}

export enum bannerRedirectionTypes {
  SPECIAL_CATEGORY = 'special-category',
  KITCHEN = 'kitchen',
  COUPON = 'coupon-description',
  DEEP_LINK = 'deepLink',
  SELLER_PRODUCT_CATEGORY = 'seller-product-category',
  SELLER_PRODUCT_SUB_CATEGORY = 'seller-product-sub-category',
  SELLER_BRAND = 'seller-brand',
  BRAND_COUPONS = 'coupon-description',
  BRAND_PRODUCTS = 'store-front-product',
  BRAND_CATEGORY = 'store-front-category',
  BRAND = 'store-front-brand',
  OTHERS = 'others'
}

export interface ISpecialCategoryConfig {
  specialCategoryId?: string,
  parentZoneId?: string,
  specialCategoryName: string,
  specialCategoryImageWithBorder?: boolean,
  specialCategoryImageOriginalFileName: string,
  specialCategoryImageS3FileName: string,
  landingPageImageOriginalFileName: string,
  specialCategoryLandingPageTitle?: string,
  specialCategoryTagId?: string,
  specialCategoryTagDetails?: ITagMasters;
  landingPageImageS3FileName: string,
  pageStyle: string,
  isActive: boolean,
  isVisible: boolean,
  isPureVeg: boolean,
  requiredLimit: number,
  createdAt?: string,
  updatedAt?: string,

  specialCategoryImageUrl?: {
    url: string;
  },
  landingPageImageUrl?: {
    url: string;
  };

  statusBarTextColor?: {
    headerColorDefault: statusBarTextColorConfig,
    headerColorSticky: statusBarTextColorConfig,
  },
  statusBarContentColor?: {
    headerColorDefault: statusBarBgColorConfig,
    headerColorSticky: statusBarBgColorConfig;
  },
  tagName?: string;
}

export interface ISpecialCategoriesResponse {
  data: {
    specialCategories: ISpecialCategoryConfig[],
    totalPages: number,
    totalSpecialCategories: number,
  };
}

export enum TagsCategory {
  CUISINE = 'cuisine',
  NEW = 'new',
  KITCHENS_BY_AVAILABILITY = 'kitchensByAvailability',
  GET_IT_IMMEDIATELY = 'getItImmediately'
}

export enum TagTypes {
  ITEM = 'item',
  KITCHEN = 'kitchen',
  KITCHEN_AND_ITEM = 'kitchenAndItem',
  PRODUCTS = 'products',
}

export interface ISpecialTags {
  tagId: string;
  tagName: string;
  tagType?: TagTypes;
  title: string;
  description?: string;
  isActive?: boolean;
  tagCategory?: TagsCategory;
  createdBy?: {
    entityType: string,
    entityId: string,
  };
}

export interface ISpecialTagsResponse {
  data: {
    tags: ISpecialTags[];
  },
}

@Injectable()
export class SpecialCategoryApiService extends RestApiService {
  // TODO: update this end point
  protected override baseApiUrl = environment.config['ADMIN_SERVICE_URL'];

  private servicePath = `${this.baseApiUrl}/special-category`;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  async createSpecialCategory(addTo: string, payload: ISpecialCategoryConfig) {
    const apiPath = `${this.servicePath}?addTo=${addTo}`;
    return this.httpPost(apiPath, payload);
  }

  async updateSpecialCategory(categoryId: string, payload: ISpecialCategoryConfig) {
    const apiPath = `${this.servicePath}/${categoryId}`;
    return this.httpPut(apiPath, payload);
  }

  async deleteSpecialCategory(categoryId: string) {
    const apiPath = `${this.servicePath}/${categoryId}`;
    return this.httpDelete(apiPath, {
      categoryId,
    });
  }

  async getSpecialCategories(query?: Record<any, (string)> | undefined):
    Promise<ISpecialCategoriesResponse> {
    const queryParams = new URLSearchParams();
    if (query) {
      const {
        skip, limit, parentZoneId, searchText, isIncludeCommonToAllZones = false,
      } = query;
      if (skip) {
        queryParams.set('skip', String(skip));
      }
      if (limit) {
        queryParams.set('limit', String(limit));
      }
      if (parentZoneId) {
        queryParams.set('parentZoneId', String(parentZoneId));
      }
      if (searchText) {
        queryParams.set('searchText', String(searchText));
      }
      if (isIncludeCommonToAllZones) {
        queryParams.set('isIncludeCommonToAllZones', String(isIncludeCommonToAllZones));
      }
    }
    const apiPath = `${this.servicePath}?${queryParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getSpecialCategoryById(categoryId: string): Promise<{ data: ISpecialCategoryConfig; }> {
    const apiPath = `${this.servicePath}/${categoryId}`;
    return this.httpGet(apiPath);
  }

  async fetchSpecialTags(queryParams?: Record<any, (string)> | undefined):
    Promise<ISpecialTagsResponse> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['skip']) {
        searchParams.set('skip', String(queryParams['skip']));
      }
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if (queryParams['searchText']) {
        searchParams.set('searchText', String(queryParams['searchText']));
      }
      if (queryParams['tagType']) {
        searchParams.set('tagType', String(queryParams['tagType']));
      }
    }
    const apiPath = `${this.baseApiUrl}/tags/?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  async fetchSpecialTagsById(tagId: string): Promise<{ data: ISpecialTags; }> {
    const apiPath = `${this.baseApiUrl}/tags/${tagId}`;
    return this.httpGet(apiPath);
  }

  async getSpecialCatgeorySequence(queryParams?: Record<string, any>)
    : Promise<{ data: ISpecialCategoryConfig[]; }> {
    const searchParams = new URLSearchParams({
    });
    if (queryParams?.['parentZoneId']) {
      searchParams.set('parentZoneId', queryParams['parentZoneId']);
    }
    if (queryParams?.['subZoneId']) {
      searchParams.set('subZoneId', queryParams['subZoneId']);
    }
    if (queryParams?.['mealTime']) {
      searchParams.set('mealTime', queryParams['mealTime']);
    }
    const apiPath = `${this.servicePath}/sequence/list?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async updateSpecialCategorySequence(
    subZoneId: string,
    mealTime: string,
    payload: { specialCategoryIds: (string | undefined)[]; },
  ): Promise<ISpecialCategoryConfig[]> {
    const apiPath = `${this.servicePath}/modify/sequence?subZoneId=${subZoneId}&mealTime=${mealTime}`;
    return this.httpPut(apiPath, payload);
  }
}
