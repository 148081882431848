import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { RestApiService, envName } from '../api.service';
import { HttpClientService } from '../http-client.service';
import { FileHandle } from '../../directives/drop-zone/drop-zone.directive';
import { ZoneTypeEnum } from '../../app/customer-admin/banner-management/scrollable-banner/scrollable-banner.component';
import { statusBarBgColorConfig, statusBarTextColorConfig } from './special-category-api.service';

export enum BannerStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface IBannerConfig {
  bannerId?: string;
  bannerTitle: string;
  bannerOriginalFileName: string;
  bannerS3FileName: string;
  effectiveFrom: string;
  expireAfter: string;
  description?: string;
  parentZoneId: string | null;
  subZoneId: string | null;
  bannerFor: string;
  isPureVegCompatible?: boolean;
  isActive: boolean;
  isAllParentZone?: boolean;
  isAllSubZone?: boolean;
  redirectTo?: string;
  fileHandle?: FileHandle;
  bannerImageUrl?: string;
  bannerType?: string;
  clickAction?: string;
  sellerType?: string;
}

export interface BannerConfig {
  data: IBannerConfig[];
}

export interface IThemeBanner {
  bannerId?: string;
  bannerTitle: string;
  bannerOriginalFileName: string;
  bannerS3FileName: string;
  effectiveFrom: string;
  expireAfter: string;
  description?: string;
  parentZoneId: string;
  subZoneId: string | null;
  bannerFor: string;
  isPureVegCompatible?: boolean;
  isActive: boolean;
  isAllParentZone?: boolean;
  isAllSubZone?: boolean;
  // redirectTo?: string;
  fileHandle?: FileHandle;
  bannerImageUrl?: string;
  bannerType?: string;
  clickAction?: string;
  parentZoneName?: string;
  headerOverLayColor: string;
  statusBarTextColor?: {
    headerColorDefault: statusBarTextColorConfig,
    headerColorSticky: statusBarTextColorConfig,
  },
  statusBarContentColor?: {
    headerColorDefault: statusBarBgColorConfig,
    headerColorSticky: statusBarBgColorConfig;
  };
  specialCategoryName?: string;
  redirection?: string;
  sellerType: string;
  deliveryTextColor?: string;
  deliveryTextBackground?: string;
  addressSelectedTypeColor?: string;
  addressSelectedColor?: string;
  accountIcon?: {
    originalFileName?: string;
    s3FileName?: string;
  };
  accountIconUrl?: string;
  profileFileHandle?: FileHandle;
}

export interface ThemeBannerConfig {
  data: IThemeBanner[];
}

@Injectable()
export class BannerApiService extends RestApiService {
  // TODO: update this end point

  protected adminApiUrl = environment.config['ADMIN_SERVICE_URL'];

  private servicePath = `${this.adminApiUrl}/banner-management`;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  // without-sequence
  async getBanners(queryParams?: Record<any, (string)> | undefined):
    Promise<ThemeBannerConfig> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['parentZoneId']) {
        searchParams.set('parentZoneId', queryParams['parentZoneId']);
      }
      if (queryParams['subZoneId']) {
        searchParams.set('subZoneId', queryParams['subZoneId']);
      }
      if (queryParams['isAllParentZone']) {
        searchParams.set('isAllParentZone', queryParams['isAllParentZone']);
      }
      if (queryParams['isAllSubZone']) {
        searchParams.set('isAllSubZone', queryParams['isAllSubZone']);
      }
      if (queryParams['bannerFor']) {
        searchParams.set('bannerFor', queryParams['bannerFor']);
      }
      if (queryParams['bannerType']) {
        searchParams.set('bannerType', queryParams['bannerType']);
      }
      if (queryParams['sellerType']) {
        searchParams.set('sellerType', queryParams['sellerType']);
      }
    }
    const apiPath = `${this.servicePath}?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  // with-sequence
  async getBannersInSequence(queryParams?: Record<any, (string)> | undefined):
    Promise<BannerConfig> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['parentZoneId']) {
        searchParams.set('parentZoneId', queryParams['parentZoneId']);
      }
      if (queryParams['subZoneId']) {
        searchParams.set('subZoneId', queryParams['subZoneId']);
      }
      if (queryParams['isAllParentZone']) {
        searchParams.set('isAllParentZone', queryParams['isAllParentZone']);
      }
      if (queryParams['isAllSubZone']) {
        searchParams.set('isAllSubZone', queryParams['isAllSubZone']);
      }
      if (queryParams['bannerFor']) {
        searchParams.set('bannerFor', queryParams['bannerFor']);
      }
      if (queryParams['bannerType']) {
        searchParams.set('bannerType', queryParams['bannerType']);
      }
      if (queryParams['sellerType']) {
        searchParams.set('sellerType', queryParams['sellerType']);
      }
    }
    const apiPath = `${this.servicePath}/get-banners-with-sequence?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async updateBanner(bannerId: string, payload: IBannerConfig) {
    const apiPath = `${this.servicePath}/${bannerId}`;
    return this.httpPut(apiPath, payload);
  }

  async updateThemeBanner(bannerId: string, payload: IThemeBanner) {
    const apiPath = `${this.servicePath}/${bannerId}`;
    return this.httpPut(apiPath, payload);
  }

  async updateBannerSequence(
    subZoneId: string,
    bannerFor: string,
    payload: object,
    parentZoneId?: string,
    sellerType?: string,
  ) {
    const searchParams = new URLSearchParams();
    if (subZoneId === ZoneTypeEnum.ALL_PARENT_ZONE) {
      searchParams.set('isAllParentZone', 'true');
    } else if (subZoneId === ZoneTypeEnum.ALL_SUB_ZONE && parentZoneId) {
      searchParams.set('isAllSubZone', 'true');
      searchParams.set('parentZoneId', parentZoneId);
    } else {
      searchParams.set('subZoneId', subZoneId);
    }
    if (bannerFor) searchParams.set('bannerFor', bannerFor);
    if (sellerType) {
      searchParams.set('sellerType', sellerType);
    }
    const apiPath = `${this.servicePath}/modify-banner-sequence?${searchParams.toString()}`;
    return this.httpPatch(apiPath, payload);
  }

  async createBanner(addTo: string, payload: IBannerConfig) {
    const apiPath = `${this.servicePath}?addTo=${addTo}`;
    return this.httpPost(apiPath, payload);
  }

  async createProductBanner(addTo: string, payload: IBannerConfig) {
    const apiPath = `${this.servicePath}?addTo=${addTo}`;
    return this.httpPost(apiPath, payload);
  }

  async createThemeBanner(addTo: string, payload: IThemeBanner) {
    const apiPath = `${this.servicePath}?addTo=${addTo}`;
    return this.httpPost(apiPath, payload);
  }

  async deleteBanner(bannerId: string) {
    const apiPath = `${this.servicePath}/${bannerId}`;
    return this.httpDelete(apiPath, {
      bannerId,
    });
  }
}
